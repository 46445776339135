import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { AppActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'am-export-menu',
  templateUrl: './export-menu.component.html',
  styleUrls: ['./export-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'export-menu-panel',
      },
    },
  ],
})
export class ExportMenuComponent {
  @Input() urlPath: string;

  exportAnalysisRunning$: Observable<boolean>;

  constructor(private readonly _store: Store<fromRoot.State>) {
    this.exportAnalysisRunning$ = this._store.pipe(select(fromRoot.getExportAnalysisRunning));
  }

  exportAnalysisResults(): void {
    this._store.dispatch(AppActions.exportAnalysisResults());
  }

  exportCsv(): void {
    this._store.dispatch(AppActions.exportProjectAsCsv());
  }
}
