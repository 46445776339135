import { Injectable } from '@angular/core';
import { SmartRiskService } from '@app/core/services';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { CategoriesActions } from '../actions';
import { getAllCategories } from '../reducers';

@Injectable()
export class CategoryEffects {
  loadCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CategoriesActions.loadCategories),
      withLatestFrom(this._store.select(getAllCategories)),
      switchMap(() =>
        this._smartRiskService.getCategories().pipe(
          map(categories => CategoriesActions.loadCategoriesSuccess({ categories })),
          catchError(error => throwError(error))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _smartRiskService: SmartRiskService
  ) {}
}
