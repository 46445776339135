import { palette } from '../const/palette';
import { DefaultTooltipParams } from '../models/echarts-type';

export const defaultTooltip: DefaultTooltipParams = {
  backgroundColor: hexToRgba(palette.grey[600], 0.85),
  textStyle: { color: palette.white[400], fontSize: 11 },
  appendToBody: true,
  extraCssText: 'border-radius:0px;',
};

export function tooltipFormatter(titles: string[], data: Array<{ name: string; values: string[] }>): string {
  const itemValueHTML = (value: string) => `<span class="cell-value">${value}</span>`;

  const itemHTML = ({ name, values }: { name: string; values: string[] }) => `
      <div class="row">
        <span class="cell-label">
          ${name}
        </span>
        ${values.map(value => itemValueHTML(value)).join('')}
      </div>
    `;

  return `
      <div class="am-charts-tooltip-header">
        ${titles.join(' - ')}
      </div>
      <div class="am-charts-tooltip-content">
        ${data.map(item => itemHTML(item)).join('')}
      </div>
    `;
}

export function tooltipPosition(
  point: Array<number | string>,
  dom: HTMLElement,
  size: { contentSize: number[] },
  yOffset: number
): Array<number | string> {
  dom.className = 'am-charts-tooltip';
  const pointX = parseInt(point[0].toString(), 10);
  const pointY = parseInt(point[1].toString(), 10);
  const tooltipWidth = size.contentSize[0];
  return [pointX - tooltipWidth / 2, pointY - size.contentSize[1] - yOffset];
}

export function rgbToHex(rgbColor: string) {
  const splitedColor = rgbColor.split(',');
  return (
    '#' +
    colorToHex(parseInt(splitedColor[0], 10)) +
    colorToHex(parseInt(splitedColor[1], 10)) +
    colorToHex(parseInt(splitedColor[2], 10))
  );
}

export function hexToRgba(hexaColor: string, opacity: number) {
  const arrBuff = new ArrayBuffer(4);
  const dataView = new DataView(arrBuff);
  dataView.setUint32(0, parseInt(hexaColor.replace('#', ''), 16), false);
  const arrByte = new Uint8Array(arrBuff);

  return `rgba(${arrByte[1]},${arrByte[2]},${arrByte[3]},${opacity})`;
}

function colorToHex(color: number): string {
  const hex = color.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
