import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackBarService } from '@app/shared/services';
import { AuthActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { throwError } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ImporterErrorsHandler } from '../error-handlers/importer-errors-handler';
import { SrapiErrorsHandler } from '../error-handlers/srapi-errors-handler';
import { ErrorMessageData, ErrorResponseMessages, ErrorResponseObject } from '../models';
import { SentryService } from '../services/sentry.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly httpErrors = [
    { code: [400], translationKey: 'http-errors.generic.invalid' },
    { code: [403], translationKey: 'http-errors.generic.forbidden' },
    { code: [404], translationKey: 'http-errors.generic.not-found' },
    { code: [500], translationKey: 'http-errors.generic.server-internal' },
    { code: [503], translationKey: 'http-errors.generic.server-unavailable' },
  ];

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly importerErrorsHandler: ImporterErrorsHandler,
    private readonly srapiErrorsHandler: SrapiErrorsHandler,
    private readonly sentryService: SentryService,
    private readonly store: Store<fromRoot.State>
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      retry(1),
      tap(
        (response: HttpResponse<ErrorResponseObject | ErrorResponseObject[]>) => {
          if (response.body) {
            this.displayResponseErrorOrWarning(response.body, response.status, response.url);
          }
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.store.dispatch(
              AuthActions.notAuthorized({ titleTranslationKey: this.getTitleTranslationKey(error.url) })
            );
            return throwError(error);
          }
          if (error.error && this.displayResponseErrorOrWarning(error.error, error.status, error.url)) {
            return throwError(error);
          }
          const httpErrorMessage = this.getHttpErrorMessage(error);
          this.snackBarService.openSnackBar('error', httpErrorMessage.translations, httpErrorMessage.titleKey);

          return throwError(error);
        }
      )
    );
  }

  private displayResponseErrorOrWarning(
    errorBody: ErrorResponseObject | ErrorResponseObject[],
    status: number,
    responseUrl: string
  ): boolean {
    if (Array.isArray(errorBody)) {
      if (this.displayArrayErrorOrWarning(errorBody, responseUrl, 'errorMessages')) {
        this.sendSentryError(errorBody, status, responseUrl);
        return true;
      }

      if (this.displayArrayErrorOrWarning(errorBody, responseUrl, 'warningMessages')) {
        return true;
      }
    } else {
      const errorMessageData = this.getErrorOrWarning(errorBody, status, responseUrl);
      if (errorMessageData) {
        this.snackBarService.openSnackBar(
          errorMessageData.type,
          errorMessageData.errorMessageData.translations,
          errorMessageData.errorMessageData.titleKey
        );
        return true;
      }
    }
    return false;
  }

  private sendSentryError(
    errorBody: ErrorResponseObject | ErrorResponseObject[],
    status: number,
    responseUrl: string
  ): void {
    this.sentryService.captureExceptionWithExtra(
      new HttpErrorResponse({ error: errorBody, status, url: responseUrl }),
      { key: 'errorDetails', value: errorBody }
    );
  }

  private displayArrayErrorOrWarning(
    errorBody: ErrorResponseObject[],
    responseUrl: string,
    errorType: 'errorMessages' | 'warningMessages'
  ): boolean {
    const classPrefix = errorType === 'errorMessages' ? 'error' : 'warning';
    const type = errorType === 'errorMessages' ? 'errors' : 'warnings';
    const errorMessages: ErrorResponseMessages = errorBody
      .filter(body => !isEmpty(body[errorType]))
      .map((body: ErrorResponseObject) => body[errorType])
      .reduce(
        (responseMessages, currentResponseMessages, index) =>
          this.reduceArrayMessages(responseMessages, currentResponseMessages, index),
        {}
      );
    if (!isEmpty(errorMessages)) {
      const errorMessageData = this.getErrorMessage(responseUrl, errorMessages, type);
      if (errorMessageData.translations.length > 0) {
        this.snackBarService.openSnackBar(classPrefix, errorMessageData.translations, errorMessageData.titleKey);
        return true;
      }
    }
    return false;
  }

  private getErrorOrWarning(
    responseBody: ErrorResponseObject,
    status: number,
    responseUrl: string
  ): { type: 'error' | 'warning'; errorMessageData: ErrorMessageData } {
    if (!isEmpty(responseBody.errorMessages)) {
      this.sendSentryError(responseBody, status, responseUrl);
      const errorMessageData = this.getErrorMessage(responseUrl, responseBody.errorMessages, 'errors');
      if (!isEmpty(errorMessageData.translations)) {
        return { type: 'error', errorMessageData };
      }
    }
    if (!isEmpty(responseBody.warningMessages)) {
      const warningMessagesData = this.getErrorMessage(responseUrl, responseBody.warningMessages, 'warnings');
      if (!isEmpty(warningMessagesData.translations)) {
        return { type: 'warning', errorMessageData: warningMessagesData };
      }
    }
  }

  private getErrorMessage(
    requestUrl: string,
    errorMessages: ErrorResponseMessages,
    type: 'errors' | 'warnings'
  ): ErrorMessageData {
    const undefinedErrorTranslationKey =
      type === 'errors' ? 'http-errors.generic.error-default' : 'http-errors.generic.warning-default';
    let errorData: ErrorMessageData;
    errorData = {
      translations: [{ key: undefinedErrorTranslationKey }],
    };
    if (requestUrl.includes(environment.base_importer_url)) {
      errorData = this.importerErrorsHandler.formatErrorMessages(requestUrl, errorMessages, type);
    }
    if (requestUrl.includes(environment.base_smart_url)) {
      errorData = this.srapiErrorsHandler.formatErrorMessages(requestUrl, errorMessages, type);
    }
    return errorData;
  }

  private getHttpErrorMessage(error: HttpErrorResponse): ErrorMessageData {
    const titleKey = this.getTitleTranslationKey(error.url);
    const httpError = this.httpErrors.find(httpErrorItem => httpErrorItem.code.includes(error.status));
    const httpErrorTranslationKey = httpError ? httpError.translationKey : 'http-errors.generic.default';
    const translations = [{ key: httpErrorTranslationKey }];

    return { titleKey, translations };
  }

  private getTitleTranslationKey(errorUrl: string) {
    let titleKey = 'http-errors.title.default';
    if (errorUrl.includes(environment.base_importer_url)) {
      titleKey = this.importerErrorsHandler.getHttpErrorTitle(errorUrl);
    }
    if (errorUrl.includes(environment.base_smart_url)) {
      titleKey = this.srapiErrorsHandler.getHttpErrorTitle(errorUrl);
    }
    return titleKey;
  }

  private reduceArrayMessages(
    errorResponseMessages: ErrorResponseMessages,
    currentResponseMessages: ErrorResponseMessages,
    index: number
  ) {
    const portfolioKey = Object.keys(currentResponseMessages).find(currentItemKey =>
      isNaN(parseInt(currentItemKey, 10))
    );

    if (portfolioKey) {
      errorResponseMessages[`${portfolioKey}_${index + 1}`] = currentResponseMessages[portfolioKey];
      delete currentResponseMessages[portfolioKey];
    }
    return { ...errorResponseMessages, ...currentResponseMessages };
  }
}
