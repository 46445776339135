export const environment = {
  production: true,
  reduxLogOnly: true,
  backend_url: '/api/backend/',
  base_smart_url: '/api/smart-risk/',
  base_importer_url: '/api/datascan/',
  base_sr_tools_url: '/api/smart-risk-tools/',
  authentication: '/api/authentication/',
  telemetry: '/api/telemetry/',
  useHash: true,
  sentryEnv: 'production',
};
