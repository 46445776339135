import { Currency, Histories } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

export const loadCurrencies = createAction('[Entities/Currencies] Load Currencies');

export const loadCurrenciesSuccess = createAction(
  '[Entities/Currencies] Load Currencies Success',
  props<{ currencies: Currency[] }>()
);

export const loadFxRatesHistories = createAction('[Entities/Currencies] Load Currencies Histories');

export const loadFxRatesHistoriesSuccess = createAction(
  '[Entities/Currencies] Load Currencies Histories Success',
  props<{ fxRatesHistories: Histories[] }>()
);
