export const csvExportProjectFields = [
  'id',
  'userId',
  'name',
  'currency',
  'creditRate',
  'financialProfile',
  'hedging',
  {
    label: 'allocationName',
    value: 'allocations.name',
  },
  {
    label: 'allocationRisk',
    value: 'allocations.allocationResult.risk',
  },
  {
    label: 'allocationReturn',
    value: 'allocations.allocationResult.return',
  },
  {
    label: 'allocationSharpeRatio',
    value: 'allocations.allocationResult.sharpeRatio',
  },
  {
    label: 'allocationNormalVar',
    value: 'allocations.allocationResult.normalVar',
  },
  {
    label: 'liquidityLabel',
    value: 'allocations.csvData.liquidityLabel',
  },
  {
    label: 'liquidityValue',
    value: 'allocations.csvData.liquidityValue',
  },
  {
    label: 'assetName',
    value: 'allocations.csvData.assetName',
  },
  {
    label: 'assetWeight',
    value: 'allocations.csvData.positionValue',
  },
  {
    label: 'backtestMaxDrawDown',
    value: 'allocations.backtestMaxDrawDown',
  },
  {
    label: 'backtestResultDate',
    value: 'allocations.csvData.backtestResultDate',
  },
  {
    label: 'backtestResultValue',
    value: 'allocations.csvData.backtestResultValue',
  },
  {
    label: 'backtestStartDate',
    value: 'backtestParameters.startDate',
  },
  {
    label: 'backtestEndDate',
    value: 'backtestParameters.endDate',
  },
  {
    label: 'backtestReference',
    value: 'backtestParameters.assetName',
  },
];
