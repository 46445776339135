import { Type } from '@angular/core';

import { AppEffects } from './app.effect';
import { AuthEffects } from './auth.effect';
import { ImportsEffects } from './imports.effect';

export const appEffects: Array<Type<AppEffects | AuthEffects | ImportsEffects>> = [
  AppEffects,
  AuthEffects,
  ImportsEffects,
];
