export type TooltipItem = {
  word: string;
  definition: string;
};

type Tooltips = {
  displayedOn: string;
  fr: { title: string; tooltips: TooltipItem[] };
  en: { title: string; tooltips: TooltipItem[] };
  nl: { title: string; tooltips: TooltipItem[] };
  es: { title: string; tooltips: TooltipItem[] };
};

/* eslint-disable max-len */
export const TOOLTIPS: Tooltips[] = [
  {
    displayedOn: 'performance',
    fr: {
      title: 'Performance',
      tooltips: [
        {
          word: 'LES RENTABILITÉS ATTENDUES LONG TERME (8-10 ans)',
          definition:
            " ne sont pas des promesses ou même des estimations des rentabilités réelles qui peuvent être réalisées par un investisseur; ce sont des indicateurs de ce que devraient être les rentabilités « d'équilibre » selon nos estimations. Dans notre modèle, les rentabilités attendues sont annualisées et incluent à la fois les variations de prix et les rendements courants.",
        },
        {
          word: 'LE RENDEMENT COURANT OU « YIELD »',
          definition:
            " est un terme financier utilisé pour les titres générant des revenus au cours de leur vie comme les obligations (coupons), l'immobilier (loyers) ou les actions (dividendes). C'est le rendement intrinsèque du titre ou du portefeuille, et en aucun cas, il ne reflète la rentabilité totale sur la vie entière du titre qui, elle, inclut les variations de prix. Dans notre modèle, les rendements courants sont prévisionnels à long terme (8-10 ans) et annualisés.",
        },
      ],
    },
    en: {
      title: 'Performance',
      tooltips: [
        {
          word: 'LONG TERM (8-10 years) EXPECTED RETURNS',
          definition:
            ' are not promises or even estimations of the real returns that may be realised by an investor; they are simply of what the ‘equilibrium’ returns should be according to our estimates. In our model, expected returns are annualized and include price changes and yields.',
        },
        {
          word: 'YIELD',
          definition:
            ' is a financial term used for securities generating a return during their life, such as bonds (coupons), real estate (rents) and equities (dividends). It is the intrinsic return of the security or portfolio and under no circumstances does it reflect the total return over the entire lifespan of the security that incorporates price changes. In our model, expected yields are long term (8-10 years) and annualized.',
        },
      ],
    },
    nl: {
      title: 'Performantie',
      tooltips: [
        {
          word: 'EXPECTED RETURNS/ POTENTIËLE VERWACHTE RENDEMENTEN',
          definition:
            " zijn geen beloftes of schattingen van de reële returns die door een investeerder gerealiseerd worden. Ze zijn indicatoren van wat 'evenwichtige' rendementen zouden kunnen zijn op lange termijn (8-10 jaar) volgens de beste schattingen. In ons model zijn zowel de meer/minwaarden als de opbrengsten (yield) begrepen in het verwachte rendement.",
        },
        {
          word: 'YIELD/OPBRENGSTEN',
          definition:
            ' is de financiële term die gebruikt wordt voor effecten die een rendement genereren, zoals obligaties (coupons), vastgoed (huur) of aandelen (dividenden). Dit is de intrinsieke potentiëel opbrengst van een effect op een gegeven tijdstip. Het reflecteert geenszins de totale return van een effect over de gehele periode, die ook prijsveranderingen inhoudt.',
        },
      ],
    },
    es: {
      title: 'Rentabilidad',
      tooltips: [
        {
          word: 'RENTABILIDADES ESPERADAS A LARGO PLAZO (8-10 años)',
          definition:
            'no son estimaciones de los rendimientos reales que podría obtener un inversor; son simplemente los rendimientos de equilibrio que deberían obtenerse según nuestras estimaciones. En nuestro modelo, los rendimientos esperados están anualizados e incluyen variaciones de precios y de rendimientos.',
        },
        {
          word: 'RETORNO',
          definition:
            'es un término financiero que se utiliza para valores que generan ingresos durante su vida, como los bonos (cupones), el inmobiliario (rentas) y la renta variable (dividendos). Es la rentabilidad potencial intrínseca de un valor o de una cartera y no refleja la rentabilidad total del valor, que incluye los cambios en el precio. En nuestro modelo, los retornos son a largo plazo (8-10 años) y se presentan anualizados.',
        },
      ],
    },
  },
  {
    displayedOn: 'risk',
    fr: {
      title: 'Risque',
      tooltips: [
        {
          word: 'LA VOLATILITÉ',
          definition:
            " est l'indicateur de risque le plus fréquemment utilisé. Il mesure l'ampleur des variations de la rentabilité d'un actif financier autour de sa moyenne (écart-type), et permet ainsi de quantifier le risque lié à la variabilité de la performance. Par exemple, lorsque la volatilité est élevée, l'espérance de gain est généralement importante, mais le risque de perte aussi. Dans notre modèle, les volatilités sont prévisionnelles à long terme (8-10 ans) et annualisées.",
        },
        {
          word: 'LA « VALUE AT RISK » (VaR)',
          definition:
            " représente la perte potentielle maximale d'un investisseur sur la valeur d'un actif ou d'un portefeuille d'actifs financiers compte tenu d'un horizon de détention et d'un intervalle de confiance (probabilité). Elle se calcule à partir d'un échantillon de données historiques. La VaR est donc une mesure du risque de perte pour un investisseur. Par exemple, si un portefeuille a une VaR 1 an de -10%, avec 95% de niveau de confiance, cela signifie qu'il y a 5% de chances que ce portefeuille baisse en valeur de plus de 10% au cours d'une année.",
        },
      ],
    },
    en: {
      title: 'Risk',
      tooltips: [
        {
          word: 'VOLATILITY',
          definition:
            ' is the most frequently used risk indicator. Volatility measures the extent of the change in the return of a financial asset relative to its mean return (standard deviation) and thus helps to quantify the risk relative to such change. For instance, when volatility is high, returns are usually expected to be high, but the risk of loss is also high. In our model they are long term (8-10 years) and annualized.',
        },
        {
          word: '« VALUE AT RISK » (VaR)',
          definition:
            ' represents the potential loss that an investor may suffer on the value of an asset or a portfolio of financial assets, taking into account a holding period and a confidence interval (probability). It is calculated using a sample of historical data. The VaR is therefore a measure of an investor’s risk of loss. For instance, if a portfolio has a 1-year VaR of -10% at a 95% confidence level, this means that there is a 5% probability that the portfolio will decrease in value by more than 10% during one year.',
        },
      ],
    },
    nl: {
      title: 'Risicoindicatoren',
      tooltips: [
        {
          word: 'VOLATILITEIT',
          definition:
            ' is de belangrijkste risico-indicator die gebruikt wordt in het Strategica-A model. Volatiliteit meet de mate van verandering van het rendement voor een assetcategorie relatief tot zijn gemiddelde rendement en laat toe om dergelijke verandering in risico te kwantificeren. Bijvoorbeeld: wanneer de volatiliteit hoog is, zijn de te verwachten rendementen hoog, maar het risico op verlies is ook hoog.',
        },
        {
          word: 'De VALUE AT RISK (VAR) ',
          definition:
            ' vertegenwoordigt het maximale verlies dat een belegger kan lijden op de waarde van een asset of portefeuille, rekening houdend met de periode en de waarschijnlijkheid. Deze wordt berekend op basis van verschillende historische data, of afgeleid van statistische regels. In simpelere bewoordingen, de Var is de maatstaf  voor de belegger van het risico op verlies. Bijvoorbeeld, als een portefeuille een 1-jaar Var  heeft van 1 mio Eur met een waarschijnlijkheid van 90 %, betekent dit dat er een kans is van 10% dat de portefeuille zal dalen in waarde met meer dan 1 mio Eur in het komende jaar.',
        },
      ],
    },
    es: {
      title: 'Riesgo',
      tooltips: [
        {
          word: 'VOLATILIDAD',
          definition:
            'El indicador de riesgo más utilizado. La volatilidad mide la variación en el rendimiento de un activo financiero, en relación con su rendimiento medio (desviación estándar) y, por lo tanto, ayuda a cuantificar el riesgo relativo de dicha variación. Por ejemplo, cuando la volatilidad es alta, generalmente se espera que las rentabilidades sean altas, siendo el riesgo de pérdida también elevado. En nuestro modelo, las volatilidades son a largo plazo (8-10 años) y se presentan anualizadas. ',
        },
        {
          word: 'Â« VALUE AT RISK Â» (VaR)',
          definition:
            'Representa la pérdida potencial que un inversor podría sufrir sobre el valor de un activo o de una cartera de activos financieros, teniendo en cuenta un período de tenencia y un intervalo de confianza (probabilidad). Se calcula utilizando una muestra de datos históricos. En términos más simples, el VaR es una medida del riesgo de pérdida de un inversor. Por ejemplo, si una cartera tiene un VaR a 1 año del -10%, con un nivel de confianza del 95%, significa que existe una probabilidad del 5% de que la cartera disminuya su valor en más de un 10% durante un año. ',
        },
      ],
    },
  },
  {
    displayedOn: 'sharpe-ratio',
    fr: {
      title: 'Ratio de Sharpe',
      tooltips: [
        {
          word: 'LE RATIO DE SHARPE',
          definition:
            " (ou prime de risque) est une mesure de la qualité du couple rendement-risque d'un actif ou d'un portefeuille. Il se calcule comme le quotient de l'excès de rentabilité de l'actif ou du portefeuille par rapport au taux sans risque divisé par le risque de l'actif ou du portefeuille (mesuré par l'écart type des rentabilités). En conséquence, plus le ratio de Sharpe est élevé, plus l'actif ou le portefeuille est attractif, plus la prise de risque est bien rémunérée. Et inversement, plus le ratio de Sharpe est faible, plus la prise de risque est mal rémunérée.",
        },
      ],
    },
    en: {
      title: 'Sharpe Ratio',
      tooltips: [
        {
          word: 'SHARPE RATIO',
          definition:
            ' measures the quality of the risk-return profile of an asset or a portfolio. It is calculated as the excess return of the asset or portfolio compared with the risk-free rate of return divided by the risk (measured by the standard deviation) of the asset or portfolio. Consequently, the higher the Sharpe ratio, the higher the risk is remunerated and the more attractive the asset or portfolio. And on the contrary, the lower the Sharpe ratio is, the lower the risk is remunerated and the less attractive the asset or portfolio.',
        },
      ],
    },
    nl: {
      title: 'Sharpe Ratio',
      tooltips: [
        {
          word: 'De SHARPE RATIO',
          definition:
            ' (of risicopremie) is een kwaliteitsmeting van de risico/rendementsverhouding van een assetcategorie of van een portefeuille. Deze wordt berekend als de mate van extra return vergeleken met een risico-vrije belegging gedeeld door het risico (gemeten via standaarddeviatie) Dit betekent : des te hoger de Sharpe ratio, des te interessanter de activaklasse of de portefeuille.',
        },
      ],
    },
    es: {
      title: 'El Ratio de Sharpe',
      tooltips: [
        {
          word: 'EL RATIO DE SHARPE',
          definition:
            'Mide la calidad del perfil de rentabilidad-riesgo de un activo o de una cartera. Se calcula como el exceso de rendimiento en comparación con la tasa libre de riesgo, dividido por el riesgo (medido por la desviación estándar) del activo o de la cartera. Cuanto mayor sea el ratio de Sharpe, mayor será la remuneración del riesgo y más atractivo será el activo o la cartera. ',
        },
      ],
    },
  },
  {
    displayedOn: 'liquidity',
    fr: {
      title: 'Liquidité',
      tooltips: [
        {
          word: 'LA LIQUIDITÉ',
          definition:
            " décrit la facilité avec laquelle un actif peut être acheté ou vendu sans que cela n'affecte son prix. Par exemple il est possible d'acheter ou de vendre une action facilement et rapidement, mais ce n'est pas le cas pour un immeuble ou une société : pour ces types d'actifs on ne peut être certain du prix et du moment de la transaction.",
        },
      ],
    },
    en: {
      title: 'Liquidity',
      tooltips: [
        {
          word: 'LIQUIDITY:',
          definition:
            ' the easier an asset can be sold at a fair price, the more liquid it is considered. For instance you can buy and sell a stock easily and quickly, but this is not the case for a building or a private company: you are not certain for how much and when your are going to sell these types of assets.',
        },
      ],
    },
    nl: {
      title: 'Liquiditeit',
      tooltips: [
        {
          word: 'LIQUIDITEIT:',
          definition:
            ' beschrijft het gemak waarmee een activa kan worden gekocht of verkocht zonder de prijs te beïnvloeden. Zo is het bijvoorbeeld mogelijk om zeer eenvoudig en snel een aandeel te kopen of verkopen, maar dit is niet het geval voor een gebouw of een particuliere onderneming: voor dit soort activa kan men niet zeker zijn van de prijs en het moment van de transactie.',
        },
      ],
    },
    es: {
      title: 'Liquidez',
      tooltips: [
        {
          word: 'LIQUIDEZ',
          definition:
            'Cuánto más fácil pueda venderse un activo a un precio justo, más líquido se considera ese activo. Por ejemplo, se puede comprar y vender una acción de una compañía cotizada muy fácil y muy rápidamente. Sin embargo, este no es el caso de un edificio o de una empresa privada: nunca sabes con certeza ni por cuánto dinero ni cuando vas a poder vender este tipo de activos. ',
        },
      ],
    },
  },
  {
    displayedOn: 'backtest',
    fr: {
      title: 'Perte Maximale Historique',
      tooltips: [
        {
          word: 'LA PERTE MAXIMALE HISTORIQUE',
          definition:
            " mesure la plus forte baisse dans la valeur d'un portefeuille sur une durée déterminée. Plus simplement, il correspond à la perte qu'aurait supportée un investisseur qui aurait acheté au plus haut et revendu au plus bas sur une période passée préalablement définie.",
        },
      ],
    },
    en: {
      title: 'Historical Maximum Drawdown',
      tooltips: [
        {
          word: 'THE HISTORICAL MAXIMUM DRAWDOWN',
          definition:
            ' is an indicator of the maximum downside loss of the portfolio over a specified time period. More simply, it refers to the maximum loss that would have supported an investor if he had bought at the highest price and sold at the lowest price over a pre-defined time period.',
        },
      ],
    },
    nl: {
      title: 'Maximum Historisch Verlies',
      tooltips: [
        {
          word: 'MAXIMUM HISTORISCH VERLIES',
          definition:
            ' meet de grootste waardedaling van een portefeuille gedurende een bepaalde periode. Eenvoudiger gezegd, het komt overeen met het verlies dat een investeerder die in een vooraf bepaalde periode in het verleden tegen de hoogste prijs zou hebben gekocht en tegen de laagste prijs zou hebben verkocht.',
        },
      ],
    },
    es: {
      title: 'Máxima Caida Histórica',
      tooltips: [
        {
          word: 'MÁXIMA CAIDA HISTÓRICA',
          definition:
            'Es un indicador de la pérdida máxima de una cartera durante un período de tiempo determinado. Se refiere a la máxima pérdida que habría soportado un inversor si hubiera comprado al precio más alto y vendido al precio más bajo, durante un período de tiempo predefinido. ',
        },
      ],
    },
  },
];
