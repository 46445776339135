import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Category } from '../../models';
import { CategoriesActions } from '../actions';

export type CategoriesState = EntityState<Category>;

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>();

const initialState: CategoriesState = adapter.getInitialState();

export const categoriesReducer = createReducer(
  initialState,
  on(CategoriesActions.loadCategoriesSuccess, (state, { categories }) => adapter.setAll(categories, state))
);
