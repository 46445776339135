<mat-icon class="close-modal" mat-dialog-close>close</mat-icon>

<div mat-dialog-title class="title">
  {{ title }}
</div>
<div mat-dialog-content class="tooltips-content">
  <p *ngFor="let tooltip of tooltips">
    <span class="word">{{ tooltip.word }}</span> {{ tooltip.definition }}
  </p>
</div>
