import { BacktestResult } from '.';
import { Allocation, CsvExportAllocation } from './allocation';
import { BacktestParameters } from './srapi-http-request';

export type Project = {
  id?: number;
  userId: string;
  name: string;
  currency: string;
  creditRate: number;
  hedge: boolean;
  financialProfile: string;
  missingAssets: boolean;
  missingFinancialProfile: boolean;
  lastModified: string;
  isArchived: boolean;
  allocations: Allocation[];
  backtestParameters?: BacktestParameters;
  backtestResults?: BacktestResult[];
};

export type CsvExportProject = {
  id?: number;
  userId: string;
  name: string;
  currency: string;
  creditRate: number;
  hedging: boolean;
  financialProfile: string;
  allocations: CsvExportAllocation[];
  backtestParameters: BacktestParameters;
};

export type ProjectData = {
  id: number;
  isArchived: boolean;
  missingAssets: boolean;
  missingFinancialProfile: boolean;
  lastModified: string;
  backtestParameters: BacktestParameters;
  backtestResults?: BacktestResult[];
};

export const defaultProjectId = -1;
