import { AvailableLiquidity, Histories, Project } from '@app/core/models';
import * as fromRoot from '@app/store/reducers';
import { Action, combineReducers, createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromAssets from './assets.reducer';
import * as fromCategories from './categories.reducer';
import * as fromCurrencies from './currencies.reducer';
import * as fromProjects from './projects.reducer';

export type State = {
  assets: fromAssets.AssetsState;
  categories: fromCategories.CategoriesState;
  currencies: fromCurrencies.CurrenciesState;
  projects: fromProjects.ProjectsState;
};

export function reducers(state: State | undefined, action: Action): State {
  return combineReducers({
    assets: fromAssets.assetsReducer,
    categories: fromCategories.categoriesReducer,
    currencies: fromCurrencies.currenciesReducer,
    projects: fromProjects.projectsReducer,
  })(state, action);
}

export const getEntitiesState: MemoizedSelector<fromRoot.State, State> = createFeatureSelector<State>('entities');

export const getEntitiesAssetsState: MemoizedSelector<fromRoot.State, fromAssets.AssetsState> = createSelector(
  getEntitiesState,
  (state: State) => state.assets
);

export const getAvailableLiquidities: MemoizedSelector<fromRoot.State, AvailableLiquidity[]> = createSelector(
  getEntitiesAssetsState,
  (assetsState): AvailableLiquidity[] => assetsState.availableLiquidities
);

export const getEntitiesCategoriesState: MemoizedSelector<fromRoot.State, fromCategories.CategoriesState> =
  createSelector(getEntitiesState, (state: State) => state.categories);

export const getEntitiesCurrenciesState: MemoizedSelector<fromRoot.State, fromCurrencies.CurrenciesState> =
  createSelector(getEntitiesState, (state: State) => state.currencies);

export const getFxRatesHistories: MemoizedSelector<fromRoot.State, Histories[]> = createSelector(
  getEntitiesCurrenciesState,
  (currencyState): Histories[] => currencyState.fxRatesHistories
);

export const getEntitiesProjectsState: MemoizedSelector<fromRoot.State, fromProjects.ProjectsState> = createSelector(
  getEntitiesState,
  (state: State) => state.projects
);

export const getSelectedProject: MemoizedSelector<fromRoot.State, Project> = createSelector(
  getEntitiesProjectsState,
  (projectsState): Project => projectsState.selectedProject
);

export const getProjectsLoading: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getEntitiesProjectsState,
  (projectsState): boolean => projectsState.loading
);

export const getSavingProject: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getEntitiesProjectsState,
  (projectsState): boolean => projectsState.savingProject
);

export const getRunningAnalysis: MemoizedSelector<fromRoot.State, boolean> = createSelector(
  getEntitiesProjectsState,
  (projectsState): boolean => projectsState.runningAnalysis
);

export const { selectAll: getAllAssets } = fromAssets.adapter.getSelectors(getEntitiesAssetsState);
export const { selectAll: getAllCategories } = fromCategories.adapter.getSelectors(getEntitiesCategoriesState);
export const { selectAll: getAllCurrencies } = fromCurrencies.adapter.getSelectors(getEntitiesCurrenciesState);
export const { selectAll: getAllProjects } = fromProjects.adapter.getSelectors(getEntitiesProjectsState);
