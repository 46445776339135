import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { Asset, AvailableLiquidity } from '../../models/asset';

export const loadAssets = createAction('[Entities/Assets] Load Assets');

export const loadAssetsSuccess = createAction('[Entities/Assets] Load Assets Success', props<{ assets: Asset[] }>());

export const setAvailableLiquidities = createAction(
  '[Entities/Assets] Set Available Liquidities',
  props<{ availableLiquidities: AvailableLiquidity[] }>()
);

export const loadAssetsHistories = createAction('[Entities/Assets] Load Assets Histories');

export const loadAssetsHistoriesSuccess = createAction(
  '[Entities/Assets] Load Assets Histories Success',
  props<{ assets: Array<Update<Asset>> }>()
);
