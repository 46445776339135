import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { defaultProjectId, Project } from '../../models';
import { ProjectsActions } from '../actions';

export type ProjectsState = EntityState<Project> & {
  selectedProject: Project;
  loading: boolean;
  savingProject: boolean;
  runningAnalysis: boolean;
};

export function selectProjectId(project: Project): number {
  return project.id ? project.id : defaultProjectId;
}

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  selectId: selectProjectId,
});

const initialState: ProjectsState = adapter.getInitialState({
  selectedProject: undefined,
  loading: false,
  savingProject: false,
  runningAnalysis: false,
});

export const projectsReducer = createReducer(
  initialState,
  on(ProjectsActions.loadUserProjects, state => ({ ...state, loading: true })),
  on(ProjectsActions.loadUserProjectsSuccess, (state, { projects }) =>
    adapter.setAll(projects, { ...state, loading: false })
  ),
  on(ProjectsActions.loadUserProjectsFailure, state => ({ ...state, loading: false })),
  on(ProjectsActions.saveSelectedProject, state => ({ ...state, savingProject: true })),
  on(ProjectsActions.addProjectFailure, ProjectsActions.updateProjectFailure, state => ({
    ...state,
    savingProject: false,
  })),
  on(ProjectsActions.addProjectSuccess, (state, { project }) =>
    adapter.addOne(project, { ...state, savingProject: false, selectedProject: project })
  ),
  on(ProjectsActions.updateProjectSuccess, (state, { project }) =>
    adapter.updateOne(project, {
      ...state,
      savingProject: false,
      selectedProject: { ...state.selectedProject, ...project.changes },
    })
  ),
  on(ProjectsActions.updateProjectArchiveStatusSuccess, (state, { project }) =>
    adapter.updateOne(project, { ...state })
  ),
  on(ProjectsActions.deleteProjectSuccess, (state, { projectId }) => adapter.removeOne(projectId, state)),
  on(ProjectsActions.getExAnteAnalysis, state => ({
    ...state,
    runningAnalysis: true,
  })),
  on(ProjectsActions.getExAnteAnalysisSuccess, (state, { selectedProject }) => ({
    ...state,
    selectedProject: { ...selectedProject },
    runningAnalysis: false,
  })),
  on(ProjectsActions.setSelectedProject, (state, { selectedProject }) => ({
    ...state,
    selectedProject: { ...selectedProject },
  })),
  on(ProjectsActions.confirmResetSelectedProject, state => ({ ...state, selectedProject: undefined }))
);
