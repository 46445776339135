import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { TelemetryEvent, TelemetryEventData } from '../models';
import { Period } from '../models/period';
import { downloadFile } from '../tools/file-saver';

const EVENT_ENDPOINT = `${environment.telemetry}event`;
const REPORT_ENDPOINT = `${EVENT_ENDPOINT}/report-group-by`;
@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  constructor(private readonly http: HttpClient) {}

  sendEvent(eventData: TelemetryEventData): Observable<TelemetryEvent> {
    return this.http.post<TelemetryEvent>(EVENT_ENDPOINT, eventData);
  }

  downloadReport(period: Period): Observable<ArrayBuffer> {
    const formatDate = (date: moment.Moment) => date.utc(true).format('L');
    const startDate = formatDate(moment(period.startDate).startOf('month'));
    const endDate = formatDate(moment(period.endDate).endOf('month'));
    const params = {
      FromDate: startDate,
      ToDate: endDate,
      FilterTag: 'financialProfile',
      GroupByTag: 'country',
      GroupByPeriod: 'MONTH',
    };
    return this.http.get(REPORT_ENDPOINT, { params, responseType: 'arraybuffer' }).pipe(
      tap(file => {
        const fileName = `telemetry-${startDate}-${endDate}.csv`;
        downloadFile(file, 'text/csv', fileName);
      })
    );
  }
}
