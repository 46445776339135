<img alt="logo" class="logo logo-{{ country$ | async }}" src="/assets/images/logo-bnp-{{ country$ | async }}.png" />

<mat-list disableRipple="true" class="top-nav">
  <mat-list-item class="clear" (click)="emitClose()">
    <mat-icon> close </mat-icon>
  </mat-list-item>
  <mat-list-item [class.active]="urlPath === '/'" (click)="onClickNavigation('')">
    <div class="am-nav-list-item">
      <mat-icon> home </mat-icon>
      <span>{{ 'sidebar.home' | transloco }}</span>
    </div>
  </mat-list-item>
  <mat-list-item [class.active]="urlPath === '/forecast'" (click)="onClickNavigation('forecast')">
    <div class="am-nav-list-item">
      <mat-icon> location_searching </mat-icon>
      <span>{{ 'sidebar.forecasts' | transloco }}</span>
    </div>
  </mat-list-item>
  <mat-list-item [class.active]="isSimulationLinkActive" [routerLink]="['/simulation']">
    <div class="am-nav-list-item">
      <am-simulation-icon></am-simulation-icon>
      <span>{{ 'sidebar.simulation' | transloco }}</span>
    </div>
  </mat-list-item>
</mat-list>

<!-- bottom part -->
<mat-list class="bottom-nav">
  <mat-list-item
    *ngIf="userIsAdmin$ | async"
    [class.active]="urlPath === '/admin'"
    (click)="onClickNavigation('admin')"
  >
    <div class="am-nav-list-item">
      <mat-icon> settings_applications </mat-icon>
      <span>{{ 'sidebar.administration' | transloco }}</span>
    </div>
  </mat-list-item>
  <mat-list-item class="help" *ngIf="(userIsAdmin$ | async) === false">
    <a target="_blank" href="{{ userGuideLink$ | async }}" accept=".pdf">
      <div class="am-nav-list-item">
        <mat-icon> help </mat-icon>
        <span>{{ 'sidebar.help' | transloco }}</span>
      </div>
    </a>
  </mat-list-item>
</mat-list>
