/* eslint-disable max-len */
export const SRAPI_REGEXP = {
  exclude: ['The diversification measure .* is not computed because it is not adapted to long-short portfolios'],
  warnings: ['Not enough data to compute measures'],
  errors: [
    '^The instrument (.*) is not found in .*$',
    '^There is not enough historical prices: (.*) of the data is missing.*$',
    '^The position needs conversion but the FX rate history for (.*) is unavailable.*$',
    '^Data of instrument (.*) do not cover the backtest period \\(StartDate to EndDate\\).*$',
    '^The following instruments do not have the needed FX rates in the data: (.*) \\(Code:.*$',
    '^The FX rate was not found',
    '^The last/current price is not available',
    '^The currency of the instrument is not available or is invalid',
    "^The instrument's data source is disabled",
    '^The historical time series contains an invalid date',
    '^Portfolio positions are all in error',
    '^The Raise Partner license is missing. Please contact Raise Partner for assistance or re-install your license',
  ],
};
