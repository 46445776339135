import { Injectable } from '@angular/core';
import { AvailableLang } from '@app/core/const/i18n';
import { palette } from '@app/core/const/palette';
import { Asset, AssetAnalytics } from '@app/core/models';
import { defaultTooltip, rgbToHex, tooltipFormatter, tooltipPosition } from '@app/core/tools/echarts-tools';
import { formatPercentAnalytic, isAssetCredit } from '@app/core/tools/smart-risk.tools';
import { TranslocoService } from '@ngneat/transloco';
import { EChartsOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ScatterDataItemOption } from 'echarts/types/src/chart/scatter/ScatterSeries';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ForecastChartsService {
  constructor(private readonly _translocoService: TranslocoService) {}

  getForecastOptions(currency: string, hedge: boolean, assetsList: Asset[], currentLang: AvailableLang): EChartsOption {
    const axisData = (axisName: string, boundaryGap: string[], padding = 30) => {
      const type = 'value' as const;
      const nameLocation = 'middle' as const;
      const fontWeight = 'bold' as const;
      return {
        type,
        splitLine: { show: true },
        name: axisName.toUpperCase(),
        nameLocation,
        nameTextStyle: {
          padding,
          fontSize: 14,
          fontWeight,
        },
        axisTick: { show: false },
        axisLabel: { show: true },
        boundaryGap,
        scale: true,
      };
    };

    const forecastData = this.getForecastData(currency, hedge, assetsList, currentLang);
    const values = forecastData.reduce(
      (reducedValues, currentValues) => {
        reducedValues.volatilities.push(currentValues.volatility);
        reducedValues.returns.push(currentValues.return);
        return reducedValues;
      },
      { volatilities: [], returns: [] }
    );
    const volatilityValuesRange = Math.max(...values.volatilities) - Math.min(...values.volatilities);
    const returnValuesRange = Math.max(...values.returns) - Math.min(...values.returns);
    const seriesData: ScatterDataItemOption[] = forecastData.map(dataItem => {
      return {
        name: dataItem.assetName,
        value: [dataItem.volatility, dataItem.return],
        label: { position: 'top' },
        itemStyle: { color: rgbToHex(dataItem.color) },
      };
    });

    return {
      baseOption: {
        grid: {
          containLabel: true,
          left: 80,
          top: 30,
          right: 60,
          bottom: 60,
        },
        tooltip: {
          ...defaultTooltip,
          show: true,
          formatter: (params): string => {
            const forecastItem = forecastData.find(item => item.assetName === params.name);
            const asset = assetsList.find(assetItem => assetItem.shortName[currentLang] === params.name);
            const titles = [asset ? asset.name[currentLang] : params.name];
            const formatterData: Array<{ name: string; values: string[] }> = [
              {
                name: this._translocoService.translate('forecast.tooltip.return').toUpperCase(),
                values: [`${forecastItem.return}%`],
              },
              {
                name: this._translocoService.translate('forecast.tooltip.volatility').toUpperCase(),
                values: [`${forecastItem.volatility}%`],
              },
              {
                name: this._translocoService.translate('forecast.tooltip.currency').toUpperCase(),
                values: [forecastItem.currency],
              },
              {
                name: this._translocoService.translate('forecast.tooltip.liquidity').toUpperCase(),
                values: [forecastItem.liquidity],
              },
            ];
            forecastData
              .filter(
                item =>
                  item.assetName !== forecastItem.assetName &&
                  item.return === forecastItem.return &&
                  item.volatility === forecastItem.volatility
              )
              .forEach(sameValuesItem => {
                titles.push(sameValuesItem.assetName);
                formatterData[0].values.push(`${sameValuesItem.return}%`);
                formatterData[1].values.push(`${sameValuesItem.volatility}%`);
                formatterData[2].values.push(sameValuesItem.currency);
                formatterData[3].values.push(sameValuesItem.liquidity);
              });

            return tooltipFormatter(titles, formatterData);
          },
          position: (point, params: CallbackDataParams, dom, _rect, size: { contentSize: number[] }) => {
            // @FIXME: label is no longer available
            // const yOffset = params.data.label.position === 'top' ? 40 : 20;
            const yOffset = 40;
            return tooltipPosition(point, dom as HTMLElement, size, yOffset);
          },
        },
        xAxis: axisData(this._translocoService.translate('forecast.axis-label.volatility'), ['0', '1%']),
        yAxis: axisData(this._translocoService.translate('forecast.axis-label.return'), ['0', '1%'], 50),
        series: [
          {
            data: this.avoidForecastLabelOverlap(seriesData, volatilityValuesRange, returnValuesRange),
            type: 'scatter',
            symbolSize: 15,
            label: {
              show: true,
              formatter: '{b}',
              color: palette.grey[600],
            },
            emphasis: { label: { fontWeight: 'bold' } },
          },
        ],
      },
      media: [
        {
          query: {
            minWidth: 925,
          },
          option: {
            series: [
              {
                label: {
                  fontSize: 12,
                  distance: 5,
                },
              },
            ],
          },
        },
        {
          query: {
            maxWidth: 924,
          },
          option: {
            series: [
              {
                label: {
                  fontSize: 11,
                  distance: 3,
                },
              },
            ],
          },
        },
      ],
    };
  }

  private getForecastData(
    currency: string,
    hedge: boolean,
    assetsList: Asset[],
    currentLang: AvailableLang
  ): Array<{
    assetName: string;
    color: string;
    currency: string;
    liquidity: string;
    return: number;
    volatility: number;
  }> {
    const analyticsFilter = (analytic: AssetAnalytics) => analytic.currency === currency && analytic.hedge === hedge;
    return assetsList
      .filter(asset => !isAssetCredit(asset))
      .map(asset => {
        const currentReturn = asset.analyticsData.returns.find(returnItem => analyticsFilter(returnItem));
        const currentVolatility = asset.analyticsData.volatilities.find(volatilityItem =>
          analyticsFilter(volatilityItem)
        );
        if (currentReturn && currentVolatility) {
          return {
            assetName: asset.shortName[currentLang],
            color: asset.color[currentLang],
            currency: asset.currency,
            liquidity: asset.liquidity[currentLang],
            return: formatPercentAnalytic(currentReturn.value, 10),
            volatility: formatPercentAnalytic(currentVolatility.value, 10),
          };
        }
      })
      .filter(data => data);
  }
  private avoidForecastLabelOverlap(
    seriesData: ScatterDataItemOption[],
    volatilityValuesRange: number,
    returnValuesRange: number
  ): ScatterDataItemOption[] {
    const volatilityRangeRatio = 100 / volatilityValuesRange;
    const returnRangeRatio = 100 / returnValuesRange;
    const maxAttempt = 10;
    let attemptCount = 0;
    let previousSeriesData = undefined;

    while (!isEqual(previousSeriesData, seriesData) && attemptCount < maxAttempt) {
      previousSeriesData = { ...seriesData };
      seriesData.forEach(dataItem => {
        const dataToUpdate = seriesData.find(dataToFilter => {
          let maxValueDifference = 10;
          if (
            dataToFilter.value[1] < dataItem.value[1] &&
            dataToFilter.label.position === 'top' &&
            dataItem.label.position === 'bottom'
          ) {
            maxValueDifference = 12;
          }
          const volatilityDifference = Math.abs(
            dataToFilter.value[0] * volatilityRangeRatio - dataItem.value[0] * volatilityRangeRatio
          );
          const returnDifference = Math.abs(
            dataToFilter.value[1] * returnRangeRatio - dataItem.value[1] * returnRangeRatio
          );

          return (
            dataToFilter.name !== dataItem.name &&
            volatilityDifference < maxValueDifference &&
            returnDifference < maxValueDifference
          );
        });
        if (dataToUpdate) {
          if (dataToUpdate.value[1] <= dataItem.value[1]) {
            dataToUpdate.label.position = 'bottom';
          } else {
            dataItem.label.position = 'bottom';
          }
        }
      });
      attemptCount++;
    }

    seriesData.forEach(dataItem => {
      const sameValuesItem = seriesData.find(
        dataToFilter =>
          dataToFilter.name !== dataItem.name &&
          dataToFilter.label.position === dataItem.label.position &&
          dataToFilter.value[0] === dataItem.value[0] &&
          dataToFilter.value[1] === dataItem.value[1]
      );
      if (sameValuesItem) {
        dataItem.label.position = sameValuesItem.label.position === 'top' ? 'bottom' : 'top';
      }
    });
    return seriesData;
  }
}
