import { Injectable } from '@angular/core';
import { SmartRiskService } from '@app/core/services';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { CurrenciesActions } from '../actions';
import { getAllCurrencies, getFxRatesHistories } from '../reducers';

@Injectable()
export class CurrencyEffects {
  loadCurrencies$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CurrenciesActions.loadCurrencies),
      withLatestFrom(this._store.select(getAllCurrencies)),
      switchMap(() =>
        this._smartRiskService.getAllCurrencies().pipe(
          map(currencies =>
            CurrenciesActions.loadCurrenciesSuccess({
              currencies: currencies.map((currency, index) => ({ id: index, label: currency })),
            })
          ),
          catchError(error => throwError(error))
        )
      )
    )
  );

  loadFxRatesHistories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CurrenciesActions.loadFxRatesHistories),
      withLatestFrom(this._store.select(getFxRatesHistories)),
      switchMap(() =>
        this._smartRiskService.getFxRatesHistories().pipe(
          map(fxRatesHistories =>
            CurrenciesActions.loadFxRatesHistoriesSuccess({
              fxRatesHistories,
            })
          ),
          catchError(error => throwError(error))
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store<fromRoot.State>,
    private readonly _smartRiskService: SmartRiskService
  ) {}
}
