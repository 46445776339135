import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { Asset, AvailableLiquidity } from '../../models';
import { AssetsActions } from '../actions';

export type AssetsState = EntityState<Asset> & {
  availableLiquidities: AvailableLiquidity[];
};

export const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>();

const initialState: AssetsState = adapter.getInitialState({ availableLiquidities: [] });

export const assetsReducer = createReducer(
  initialState,
  on(AssetsActions.loadAssetsSuccess, (state, { assets }) => adapter.setAll(assets, state)),
  on(AssetsActions.setAvailableLiquidities, (state, { availableLiquidities }) => ({ ...state, availableLiquidities })),
  on(AssetsActions.loadAssetsHistoriesSuccess, (state, { assets }) => adapter.updateMany(assets, state))
);
