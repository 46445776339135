import { BreakpointObserver } from '@angular/cdk/layout';
import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AVAILABLE_COUNTRIES } from '@app/core/const/country';
import { AppActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AVAILABLE_LANGS, AvailableLang } from './core/const/i18n';

@Component({
  selector: 'am-root',
  template: `
    <ng-container *ngIf="urlPath$ | async as urlPath">
      <am-layout *ngIf="urlPath !== '/login'; else loginPage" [urlPath]="urlPath"></am-layout>
    </ng-container>
    <ng-template #loginPage>
      <router-outlet></router-outlet>
    </ng-template>
  `,
})
export class AppComponent {
  urlPath$: Observable<string>;

  constructor(
    @Inject(APP_BASE_HREF) private readonly baseHref: string,
    router: Router,
    private readonly store: Store<fromRoot.State>,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly translocoService: TranslocoService,
    private readonly meta: Meta
  ) {
    this.meta.addTag({ name: 'app-version', content: '1.1.0' });

    this.urlPath$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => router.url)
    );

    this.breakpointObserver
      .observe('(min-width: 1281px)')
      .pipe(
        map(breakpointState => breakpointState.matches),
        distinctUntilChanged()
      )
      .subscribe(isScreenWide => {
        this.store.dispatch(AppActions.isScreenWideChanged({ isScreenWide }));
      });

    const country = AVAILABLE_COUNTRIES.find(availableCountry => availableCountry === this.baseHref.replace('/', ''));
    this.store.dispatch(AppActions.setCountry({ country }));
    this.translocoService.setAvailableLangs(AVAILABLE_LANGS[country]);
    const currentLang = AVAILABLE_LANGS[country].find(lang => lang === this.translocoService.getActiveLang());
    this.store.dispatch(
      AppActions.updateLanguage({
        currentLang: currentLang ? currentLang : AvailableLang.en,
      })
    );
  }
}
