import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'am-theme-menu',
  templateUrl: './theme-menu.component.html',
  styleUrls: ['./theme-menu.component.scss'],
})
export class ThemeMenuComponent implements OnInit {
  availableThemes: string[];

  activeTheme: string;

  private readonly _dom: Document;

  constructor(@Inject(DOCUMENT) document: Document) {
    this._dom = document;
  }

  ngOnInit(): void {
    this.availableThemes = ['am-light-theme', 'am-dark-theme', 'am-test-theme'];
    this.activeTheme = 'am-dark-theme';
  }

  themeChange(theme: string): void {
    this._dom.body.classList.replace(this.activeTheme, theme);
    this.activeTheme = theme;
  }
}
