<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenavRef
    *ngIf="{ value: isScreenWide$ | async } as isScreenWide"
    class="sidenav"
    [mode]="isScreenWide.value ? 'side' : 'over'"
    [opened]="isScreenWide.value"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
  >
    <am-sidebar [urlPath]="urlPath" (closeSidenav)="closeSidenav()"></am-sidebar>
  </mat-sidenav>
  <mat-sidenav-content *ngIf="sidenav" class="sidenav-content">
    <header class="header">
      <div class="open-sidenav-container" [class.visible]="!sidenav.opened">
        <mat-list class="nav-list">
          <mat-list-item>
            <mat-icon (click)="openSideNav()"> menu </mat-icon>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="header-brand">
        <div class="am-app-name header-app-name">ALLOCATION DESIGNER</div>
        <div class="mat-display-1 header-brand-name">by Strategic-A</div>
      </div>
      <div class="header-right">
        <mat-spinner
          *ngIf="urlPath === '/analysis' && exportAnalysisRunning$ | async"
          [mode]="'indeterminate'"
          class="am-spinner export-spinner"
          [diameter]="30"
        ></mat-spinner>
        <am-export-menu [urlPath]="urlPath" />
        <am-theme-menu *ngIf="isDevMode" />
        <am-lang-menu />
        <am-user-menu />
      </div>
    </header>
    <div class="content">
      <router-outlet><ng-content></ng-content></router-outlet>
      <am-footer />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
