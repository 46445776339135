import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private readonly _translocoService: TranslocoService) {}

  transform(value: string, pattern = 'mediumDate'): string {
    return formatDate(value, pattern, this._translocoService.getActiveLang());
  }
}
