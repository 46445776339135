import { Component, OnInit } from '@angular/core';
import { AvailableLang } from '@app/core/const/i18n';
import { AppActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { AvailableLangs } from '@ngneat/transloco/lib/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'am-lang-menu',
  templateUrl: './lang-menu.component.html',
  styleUrls: ['./lang-menu.component.scss'],
})
export class LangMenuComponent implements OnInit {
  availableLangs: AvailableLangs;

  activeLang$: Observable<string>;

  constructor(
    private readonly _store: Store<fromRoot.State>,
    private readonly _translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.availableLangs = this._translocoService.getAvailableLangs();
    this.activeLang$ = this._translocoService.langChanges$;
  }

  langChanged(lang: string): void {
    this._store.dispatch(AppActions.updateLanguage({ currentLang: lang as AvailableLang }));
    this._translocoService.setActiveLang(lang);
  }
}
