import { Type } from '@angular/core';

import { AssetEffects } from './assets.effect';
import { CategoryEffects } from './categories.effect';
import { CurrencyEffects } from './currencies.effect';
import { ProjectEffects } from './projects.effect';

export const entitiesEffects: Array<Type<AssetEffects | CategoryEffects | CurrencyEffects | ProjectEffects>> = [
  AssetEffects,
  CategoryEffects,
  CurrencyEffects,
  ProjectEffects,
];
