import { Project } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

import { BacktestSetupParams, FirstAndLastDate } from '../models/backtest-types';

export const runBacktest = createAction(
  '[Analysis Results/Backtest] Run Backtest',
  props<{ backtestSetupParams?: BacktestSetupParams }>()
);

export const setFirstAndLastDate = createAction(
  '[Analysis Results/Backtest] Set First And Last Date',
  props<{ firstAndLastDate: FirstAndLastDate }>()
);

export const setBacktestSetupParams = createAction(
  '[Analysis Results/Backtest] Set Backtest Setup Params',
  props<{ backtestSetupParams: BacktestSetupParams }>()
);

export const getBacktestResults = createAction(
  '[Analysis Results/Backtest] Get Backtest Results',
  props<{ project: Project }>()
);

export const getBacktestResultsSuccess = createAction('[Analysis Results/Backtest] Get Backtest Results Success');

export const getBacktestResultsFailure = createAction('[Analysis Results/Backtest] Get Backtest Results Failure');
