import { Allocation, PortfolioRequest } from '../models';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class SrapiRequestAdapter {
  static allocationToPortfolioRequest(
    allocation: Allocation,
    projectCurrency: string,
    benchmark?: Allocation
  ): PortfolioRequest {
    const protfolioRequest: PortfolioRequest = {
      portfolioId: allocation.id.toString(),
      currency: projectCurrency,
      portfolioType: 'weight',
      dataSource: 'csv',
      totalPortfolioValue: 1,
      cashPosition: [{ currency: projectCurrency, position: 0, riskFreeRate: 0 }],
      positions: allocation.positions.map(pos => ({
        instrumentId: pos.instrumentId.toString(),
        position: pos.positionValue / 100,
      })),
    };
    if (benchmark) {
      protfolioRequest.benchmark = SrapiRequestAdapter.allocationToPortfolioRequest(benchmark, projectCurrency);
    }
    return protfolioRequest;
  }
}
