import { AvailableCountry } from '@app/core/const/country';
import { AvailableLang } from '@app/core/const/i18n';
import { TelemetryDataAction, TranslationObject } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

export const httpFailure = createAction('[App/HTTP] HTTP Failure', props<{ messageKey: TranslationObject }>());

export const httpSuccess = createAction('[App/HTTP] HTTP Success', props<{ messageKey: TranslationObject }>());

export const updateDisclaimer = createAction(
  '[App/Disclaimer] Update Disclaimer',
  props<{ disclaimerToDisplay: string }>()
);

export const updateLanguage = createAction('[App/Language] Update Language', props<{ currentLang: AvailableLang }>());

export const setCountry = createAction('[App/Country] Set Country', props<{ country: AvailableCountry }>());

export const exportAnalysisResults = createAction('[App/Export] Export Analysis Results');

export const exportAnalysisResultsSuccess = createAction('[App/Export] Export Analysis Results Success');

export const exportAnalysisResultsFailure = createAction(
  '[App/Export] Export Analysis Results Failure',
  props<{ error?: TranslationObject }>()
);

export const exportProjectAsCsv = createAction('[App/Export] Export Project As CSV');

export const exportProjectAsCsvFailure = createAction('[App/Export] Export Project As CSV Failure');

export const sendTelemetryEvent = createAction(
  '[App/Telemetry] Send Telemetry Event',
  props<{ telemetryData: TelemetryDataAction }>()
);

export const setUpdateEntitiesAt = createAction(
  '[App/Entities] Set Update Entities At',
  props<{ updateEntitiesAt: string }>()
);

export const isScreenWideChanged = createAction(
  '[App/Screen Wide] Screen Wide Changed',
  props<{ isScreenWide: boolean }>()
);
