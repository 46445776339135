import { ImporterRegexp } from '../models';

/* eslint-disable max-len */
export const IMPORTER_REGEXP: ImporterRegexp = {
  warnings: [
    {
      worksheetNameIndex: undefined,
      regexp: '^Matrix (.*) was not SDP and has been filtered with eigen value (.*)\\.$',
    },
  ],
  errors: [
    { worksheetNameIndex: undefined, regexp: '^Asset classes in categories differ in file .*\\.$' },
    {
      worksheetNameIndex: 2,
      regexp: '^No asset class key found in cell (.*) in worksheet (.*) of file .*\\.$',
    },
    { worksheetNameIndex: 3, regexp: '^Value (.*) not found in cell (.*) in worksheet (.*)\\.$' },
    {
      worksheetNameIndex: 3,
      regexp: '^Asset (.*) has a value for column (.*) in another worksheet, but not in worksheet (.*)\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^Price in cell (.*) in worksheet (.*) of file .* cannot be 0\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^Missing or invalid value in cell (.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: 1,
      regexp: '^Covariance matrix does not have the same number of lines and columns in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: undefined,
      regexp: '^Different number of asset classes found in file .* :(.*)\\.$',
    },
    {
      worksheetNameIndex: undefined,
      regexp: '^Different number of categories found in file .* :(.*)\\.$',
    },
    { worksheetNameIndex: undefined, regexp: '^Missing value for forex (.*) at date: (.*)\\.$' },
    {
      worksheetNameIndex: 2,
      regexp: '^Invalid currency for asset class (.*) in worksheet (.*) of file .*\\.$',
    },
    { worksheetNameIndex: undefined, regexp: '^Forex (.*) is invalid in Excel file: .*\\.$' },
    {
      worksheetNameIndex: undefined,
      regexp: '^An item with the same key \\((.*)\\) has already been added from file : .*$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^Missing or invalid covariance value in cell \\((.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^Missing currency for asset class (.*) in worksheet (.*) of file .*\\.$',
    },
    { worksheetNameIndex: undefined, regexp: '^Several files found matching the prefix: (.*)\\.$' },
    { worksheetNameIndex: undefined, regexp: '^No valid asset class data found in file: .*\\.$' },
    {
      worksheetNameIndex: 2,
      regexp: '^No asset class found on line (.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: undefined,
      regexp: '^No valid classification data found in file: .*\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^No data found on line (.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^No forex data found for forex (.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^No forex found on line (.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: 2,
      regexp: '^No history found on element (.*) in worksheet (.*) of file .*\\.$',
    },
    { worksheetNameIndex: undefined, regexp: '^No valid matrix data found in file: .*\\.$' },
    { worksheetNameIndex: undefined, regexp: '^No valid view data found in file: .*\\.$' },
    { worksheetNameIndex: 1, regexp: '^Worksheet (.*) not found in Excel file: .*\\.$' },
    {
      worksheetNameIndex: 2,
      regexp: '^Duplicate asset class Id found in cell (.*) in worksheet (.*) of file .*\\.$',
    },
    {
      worksheetNameIndex: undefined,
      regexp: '^The mandatory field (.*) is missing in the uploaded file\\.$',
    },
    {
      worksheetNameIndex: undefined,
      regexp:
        '^The Raise Partner license is missing. Please contact Raise Partner for assistance or re-install your license',
    },
  ],
};
