import { Allocation, BacktestParameters, Position, Project } from '../models';
import {
  BackendAllocation,
  BackendBacktestParameters,
  BackendPosition,
  BackendProject,
} from '../models/backend-http-request';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class BackendRequestAdapter {
  static projectToProjectRequest(project: Project): BackendProject {
    const {
      id,
      userId,
      name,
      currency,
      creditRate,
      financialProfile,
      hedge: hedging,
      lastModified,
      isArchived,
    } = project;
    return {
      id,
      userId,
      name,
      currency,
      creditRate,
      financialProfile,
      hedging,
      lastModified,
      isArchived,
      allocationModels: project.allocations.map(allocation =>
        this.allocationToAllocationRequest(allocation, project.id)
      ),
      backtestParameters: project.backtestParameters
        ? this.backtestToBacktestRequest(project.backtestParameters, project.id)
        : undefined,
    };
  }

  private static allocationToAllocationRequest(allocation: Allocation, projectId: number): BackendAllocation {
    return {
      projectId,
      name: allocation.name,
      positions: allocation.positions.map(position => this.positionToPositionRequest(position, allocation.id)),
    };
  }

  private static backtestToBacktestRequest(
    backtestParameters: BacktestParameters,
    projectId: number
  ): BackendBacktestParameters {
    return {
      projectId,
      benchmark: backtestParameters.benchmark.positions[0].instrumentId.toString(),
      startDate: backtestParameters.startDate,
      endDate: backtestParameters.endDate,
    };
  }

  private static positionToPositionRequest(position: Position, allocationId: number): BackendPosition {
    return {
      allocationModelId: allocationId,
      assetClass: position.instrumentId.toString(),
      weight: position.positionValue,
    };
  }
}
