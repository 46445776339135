import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { Project } from '../../models/project';

export const loadUserProjects = createAction('[Entities/Projects] Load User Projects');

export const loadUserProjectsSuccess = createAction(
  '[Entities/Projects] Load User Projects Success',
  props<{ projects: Project[] }>()
);

export const loadUserProjectsFailure = createAction('[Entities/Projects] Load User Projects Failure');

export const addProject = createAction('[Entities/Projects] Add Project', props<{ project: Project }>());

export const addProjectSuccess = createAction('[Entities/Projects] Add Project Success', props<{ project: Project }>());

export const addProjectFailure = createAction('[Entities/Projects] Add Project Failure');

export const updateProject = createAction('[Entities/Projects] Update Project', props<{ project: Project }>());

export const updateProjectSuccess = createAction(
  '[Entities/Projects] Update Project Success',
  props<{ project: Update<Project> }>()
);

export const updateProjectFailure = createAction('[Entities/Projects] Update Project Failure');

export const updateProjectArchiveStatus = createAction(
  '[Entities/Projects] Update Project Archive Status',
  props<{ project: Project }>()
);

export const updateProjectArchiveStatusSuccess = createAction(
  '[Entities/Projects]  Update Project Archive Status Success',
  props<{ project: Update<Project> }>()
);

export const deleteProject = createAction('[Entities/Projects] Delete Project', props<{ projectId: number }>());

export const deleteProjectSuccess = createAction(
  '[Entities/Projects] Delete Project Success',
  props<{ projectId: number }>()
);

export const saveSelectedProject = createAction('[Entities/Projects] Save Selected Project');

export const setSelectedProject = createAction(
  '[Entities/Projects] Set Selected Project',
  props<{ selectedProject: Project }>()
);

export const resetSelectedProject = createAction(
  '[Entities/Projects] Reset Selected Project',
  props<{ route?: string }>()
);

export const confirmResetSelectedProject = createAction(
  '[Entities/Projects] Confirm Reset Selected Project',
  props<{ route?: string }>()
);

export const cancelResetSelectedProject = createAction('[Entities/Projects] Cancel Reset Selected Project');

export const getExAnteAnalysis = createAction('[Entities/Projects] Get ExAnte Analysis', props<{ project: Project }>());

export const getExAnteAnalysisSuccess = createAction(
  '[Entities/Projects] Get ExAnte Analysis Success',
  props<{ selectedProject: Project }>()
);

export const getExAnteAnalysisFailure = createAction('[Entities/Projects] Get ExAnte Analysis Failure');
