export enum AvailableImport {
  covariancesViews = 'covariancesViews',
  namesClassifications = 'namesClassifications',
  forexHistories = 'forexHistories',
}

export const AVAILABLE_IMPORTS: AvailableImport[] = [
  AvailableImport.covariancesViews,
  AvailableImport.namesClassifications,
  AvailableImport.forexHistories,
];

export const AVAILABLE_IMPORTS_URLS: Record<AvailableImport, string> = {
  covariancesViews: 'covariances-views',
  namesClassifications: 'names-classifications',
  forexHistories: 'forex-histories',
};
