import { Injectable } from '@angular/core';
import { AvailableImport } from '@app/core/const/importer';
import { IMPORTER_REGEXP } from '@app/core/const/importer-errors';
import { ErrorMessageData, ErrorResponseMessages, ImporterErrorMessage } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class ImporterErrorsHandler {
  getHttpErrorTitle(requestUrl: string): string {
    if (requestUrl.includes('last-import')) {
      return 'http-errors.importer.title.latest-import';
    }
    if (requestUrl.includes(AvailableImport.covariancesViews.toLowerCase())) {
      return 'http-errors.importer.title.covariances-views';
    }
    if (requestUrl.includes(AvailableImport.namesClassifications.toLowerCase())) {
      return 'http-errors.importer.title.names-classifications';
    }
    if (requestUrl.includes(AvailableImport.forexHistories.toLowerCase())) {
      return 'http-errors.importer.title.forex-histories';
    }
    return 'http-errors.importer.title.default';
  }

  formatErrorMessages(
    requestUrl: string,
    errorMessages: ErrorResponseMessages,
    type: 'errors' | 'warnings'
  ): ErrorMessageData {
    const errorMessagesData: ImporterErrorMessage[] = [];
    const flattenedErrorMessages: string[] = [].concat(...Object.values(errorMessages));

    let titleKey: string;
    if (type === 'errors') {
      titleKey = this.getHttpErrorTitle(requestUrl);
    }

    flattenedErrorMessages.forEach(errorMessage => {
      IMPORTER_REGEXP[type].forEach((errorRegexp, regexpIndex) => {
        const matchedError = errorMessage.match(new RegExp(errorRegexp.regexp));
        if (matchedError) {
          const worksheetName = errorRegexp.worksheetNameIndex
            ? matchedError[errorRegexp.worksheetNameIndex]
            : undefined;
          const existingMessage = errorMessagesData.find(
            errorMessageData =>
              errorMessageData.availableErrorIndex === regexpIndex && errorMessageData.worksheet === worksheetName
          );
          if (existingMessage) {
            existingMessage.messageData.forEach((dataItem, index) =>
              dataItem.push(
                matchedError.filter(
                  (_match, matchIndex) => matchIndex !== 0 && matchIndex !== errorRegexp.worksheetNameIndex
                )[index]
              )
            );
            return;
          }
          errorMessagesData.push({
            availableErrorIndex: regexpIndex,
            worksheet: worksheetName,
            messageKey: `http-errors.importer.${type}.${regexpIndex}`,
            messageData: matchedError
              .filter((_match, matchIndex) => matchIndex !== 0 && matchIndex !== errorRegexp.worksheetNameIndex)
              .map(match => [match]),
          });
        }
      });
    });
    if (errorMessagesData.length === 0) {
      return {
        titleKey,
        translations: [
          { key: type === 'errors' ? 'http-errors.importer.errors.default' : 'http-errors.importer.warnings.default' },
        ],
      };
    }

    // we don't want to display too many error messages so that the snackbar isn't too big
    const translations = errorMessagesData.slice(0, 7).map(errorMessageData => {
      const params: Record<string, string> = {};
      errorMessageData.messageData.forEach((dataItem, index) => {
        const stringifyData = dataItem.join(', ');
        params[`param${index}`] = stringifyData.length > 100 ? `${stringifyData.slice(0, 100)}...` : stringifyData;
      });
      if (errorMessageData.worksheet) {
        params.worksheet = errorMessageData.worksheet;
      }
      return { key: errorMessageData.messageKey, params };
    });
    return { titleKey, translations };
  }
}
