import { Currency, Histories } from '@app/core/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CurrenciesActions } from '../actions';

export type CurrenciesState = EntityState<Currency> & {
  fxRatesHistories: Histories[];
};

export const adapter: EntityAdapter<Currency> = createEntityAdapter<Currency>();

const initialState: CurrenciesState = adapter.getInitialState({ fxRatesHistories: [] });

export const currenciesReducer = createReducer(
  initialState,
  on(CurrenciesActions.loadCurrenciesSuccess, (state, { currencies }) => adapter.setAll(currencies, state)),
  on(CurrenciesActions.loadFxRatesHistoriesSuccess, (state, { fxRatesHistories }) => ({ ...state, fxRatesHistories }))
);
