import { Injectable } from '@angular/core';
import { BacktestActions } from '@app/analysis-results/actions';
import { AssetsActions, CategoriesActions, CurrenciesActions, ProjectsActions } from '@app/core/entities/actions';
import * as fromEntities from '@app/core/entities/reducers';
import { Asset, Category, Currency, Histories, Project } from '@app/core/models';
import { TelemetryService } from '@app/core/services';
import { CsvExportService } from '@app/core/services/csv-export.service';
import { PptxExportService } from '@app/core/services/pptx-export.service';
import { SnackBarService } from '@app/shared/services';
import { AppActions, ImportsActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { isEqual } from 'lodash';
import moment from 'moment';
import { throwError } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  flatMap,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class AppEffects {
  httpFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.httpFailure),
        tap(error => {
          this.snackBarService.openSnackBar('error', [error.messageKey], undefined, 5000);
        })
      ),
    { dispatch: false }
  );

  httpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.httpSuccess),
        tap(success => {
          this.snackBarService.openSnackBar('success', [success.messageKey], undefined, 5000);
        })
      ),
    { dispatch: false }
  );

  exportAnalysisResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.exportAnalysisResults),
      withLatestFrom(
        this.store.select(fromEntities.getAllAssets),
        this.store.select(fromEntities.getAvailableLiquidities),
        this.store.select(fromRoot.getCountry),
        this.store.select(fromRoot.getCurrentLang)
      ),
      switchMap(([_, assetsList, availableLiquidities, country, currentLang]) => {
        return this.store.pipe(
          select(fromEntities.getSelectedProject),
          filter(selectedProject => !!selectedProject && !!selectedProject.backtestResults),
          take(1),
          switchMap(selectedProject => {
            if (!selectedProject || selectedProject.allocations.length < 2) {
              return [AppActions.exportAnalysisResultsFailure({})];
            }
            return this.pptxExportService
              .getAnalysisResultsExport(selectedProject, assetsList, availableLiquidities, country, currentLang)
              .pipe(
                map(() => AppActions.exportAnalysisResultsSuccess()),
                catchError(error => {
                  const messageKey = JSON.parse(error.message);
                  this.store.dispatch(AppActions.exportAnalysisResultsFailure({ error: messageKey }));
                  return throwError(error);
                })
              );
          })
        );
      })
    )
  );

  exportAnalysisResultsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.exportAnalysisResultsFailure),
        tap(({ error }) => {
          if (error != null) {
            this.snackBarService.openSnackBar('error', [error], undefined, 5000);
          } else {
            this.snackBarService.openSnackBar('error', [{ key: 'errors.export.missing-allocations' }], undefined, 5000);
          }
        })
      ),
    { dispatch: false }
  );

  exportProjectAsCsv$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.exportProjectAsCsv),
        withLatestFrom(this.store.select(fromEntities.getAllAssets), this.store.select(fromRoot.getCurrentLang)),
        switchMap(([_, assets, currentLang]) => {
          return this.store.pipe(
            select(fromEntities.getSelectedProject),
            filter(selectedProject => !!selectedProject && !!selectedProject.backtestResults),
            take(1),
            tap(
              selectedProject => {
                this.csvExportService.exportProjectAsCsv(selectedProject, assets, currentLang);
              },
              error => {
                const messageKey = JSON.parse(error.message);
                this.store.dispatch(AppActions.httpFailure({ messageKey }));
                return throwError(error);
              }
            )
          );
        })
      ),
    { dispatch: false }
  );

  runBacktestBeforeExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.exportProjectAsCsv, AppActions.exportAnalysisResults),
      withLatestFrom(
        this.store.pipe(
          select(fromEntities.getSelectedProject),
          filter(selectedProject => !!selectedProject && !selectedProject.backtestResults)
        )
      ),
      distinctUntilKeyChanged('1', (previousProject, project) => isEqual(previousProject, project)),
      switchMap(([action]) => [action, BacktestActions.runBacktest({})])
    )
  );

  sendTelemetryEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.sendTelemetryEvent),
        withLatestFrom(this.store.select(fromRoot.getCurrentUser), this.store.select(fromRoot.getCountry)),
        flatMap(([{ telemetryData }, currentUser, country]) => {
          const tags = { ...telemetryData.tags, country };
          return this.telemetryService.sendEvent({ ...telemetryData, userId: currentUser.nameId, tags });
        })
      ),
    { dispatch: false }
  );

  routerNavigated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: RouterNavigationAction) => action.payload.event.url !== '/login'),
      withLatestFrom(
        this.store.select(fromEntities.getAllProjects),
        this.store.select(fromEntities.getAllAssets),
        this.store.select(fromEntities.getAllCategories),
        this.store.select(fromEntities.getAllCurrencies),
        this.store.select(fromEntities.getFxRatesHistories),
        this.store.select(fromRoot.getUpdateEntitiesAt)
      ),
      switchMap(([action, projects, assets, categories, currencies, fxRatesHistories, updateEntitiesAt]) => {
        const actionsToDispatchToUpdate =
          updateEntitiesAt && moment(updateEntitiesAt).isBefore(moment())
            ? this.getActionsToDispatchToUpdate(assets, categories, currencies, fxRatesHistories)
            : [];
        return [
          ...this.getActionsToDispatchOnNavigation(action.payload.event.url, projects, assets, categories, currencies),
          ...actionsToDispatchToUpdate,
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<fromRoot.State>,
    private readonly snackBarService: SnackBarService,
    private readonly pptxExportService: PptxExportService,
    private readonly csvExportService: CsvExportService,
    private readonly telemetryService: TelemetryService
  ) {}

  private getActionsToDispatchOnNavigation(
    url: string,
    projects: Project[],
    assets: Asset[],
    categories: Category[],
    currencies: Currency[]
  ): Array<TypedAction<string>> {
    const actions: Array<TypedAction<string>> = [];
    const entitiesToLoadForUrls = {
      project: ['/', '/simulation'],
      asset: ['/', '/simulation', '/forecast'],
      category: ['/simulation'],
      currency: ['/simulation', '/forecast'],
    };
    const entityNeedsToBeLoaded = (
      entity: Array<Asset | Category | Currency | Project>,
      entityName: 'asset' | 'category' | 'currency' | 'project'
    ) => {
      return (!entity || entity.length === 0) && entitiesToLoadForUrls[entityName].includes(url);
    };
    if (entityNeedsToBeLoaded(projects, 'project')) {
      actions.push(ProjectsActions.loadUserProjects());
    }
    if (entityNeedsToBeLoaded(assets, 'asset')) {
      actions.push(AssetsActions.loadAssets());
    }
    if (entityNeedsToBeLoaded(categories, 'category')) {
      actions.push(CategoriesActions.loadCategories());
    }
    if (entityNeedsToBeLoaded(currencies, 'currency')) {
      actions.push(CurrenciesActions.loadCurrencies());
    }
    if (url === '/analysis') {
      actions.push(AssetsActions.loadAssetsHistories());
      actions.push(CurrenciesActions.loadFxRatesHistories());
    }
    actions.push(ImportsActions.getAllImportsDates());
    return actions;
  }

  private getActionsToDispatchToUpdate(
    assets: Asset[],
    categories: Category[],
    currencies: Currency[],
    fxRatesHistories: Histories[]
  ): Array<TypedAction<string>> {
    const actions: Array<TypedAction<string>> = [];
    const entityNotEmpty = (entity: unknown[]) => entity && entity.length > 0;

    if (entityNotEmpty(assets)) {
      actions.push(AssetsActions.loadAssets());
    }
    if (entityNotEmpty(categories)) {
      actions.push(CategoriesActions.loadCategories());
    }
    if (entityNotEmpty(currencies)) {
      actions.push(CurrenciesActions.loadCurrencies());
    }
    if (entityNotEmpty(fxRatesHistories)) {
      actions.push(CurrenciesActions.loadFxRatesHistories());
    }
    actions.push(AppActions.setUpdateEntitiesAt({ updateEntitiesAt: undefined }));
    return actions;
  }
}
