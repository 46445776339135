import { Component, OnInit } from '@angular/core';
import { AuthActions } from '@app/store/actions';
import * as fromRoot from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'am-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  username$: Observable<string>;
  userIsAdmin$: Observable<boolean>;

  constructor(private readonly _store: Store<fromRoot.State>) {}

  ngOnInit(): void {
    this.userIsAdmin$ = this._store.select(fromRoot.getCurrentUserIsAdmin);
    this.username$ = this._store.select(fromRoot.getCurrentUserUsername);
  }

  logout(): void {
    this._store.dispatch(AuthActions.logoutUser());
  }
}
