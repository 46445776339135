import { LatestImportData } from '@app/core/models';
import { Action, createReducer, on } from '@ngrx/store';

import { ImportsActions } from '../actions';

export type ImportsState = {
  covariancesViews: LatestImportData;
  namesClassifications: LatestImportData;
  forexHistories: LatestImportData;
};

const initialState: ImportsState = {
  covariancesViews: {
    date: undefined,
    isImporting: false,
    recentlyImported: false,
    panelExpanded: false,
  },
  namesClassifications: {
    date: undefined,
    isImporting: false,
    recentlyImported: false,
    panelExpanded: false,
  },
  forexHistories: {
    date: undefined,
    isImporting: false,
    recentlyImported: false,
    panelExpanded: false,
  },
};

const importsReducer = createReducer(
  initialState,
  on(ImportsActions.getAllImportsDatesSuccess, (state, { allImportsDates }) => ({
    ...state,
    covariancesViews: { ...state.covariancesViews, date: allImportsDates.covariancesViews },
    namesClassifications: { ...state.namesClassifications, date: allImportsDates.namesClassifications },
    forexHistories: { ...state.forexHistories, date: allImportsDates.forexHistories },
  })),
  on(ImportsActions.getAllImportsDatesFailure, (state, { errorLabel }) => ({
    ...state,
    covariancesViews: { ...state.covariancesViews, date: errorLabel },
    namesClassifications: { ...state.namesClassifications, date: errorLabel },
    forexHistories: { ...state.forexHistories, date: errorLabel },
  })),
  on(ImportsActions.getOneImportDateSuccess, (state, action) => ({
    ...state,
    [action.uploadedFile]: {
      ...state[action.uploadedFile],
      date: action.date,
      isImporting: state[action.uploadedFile].isImporting,
    },
  })),
  on(ImportsActions.getOneImportDateFailure, (state, action) => ({
    ...state,
    [action.uploadedFile]: {
      ...state[action.uploadedFile],
      date: action.errorLabel,
      isImporting: state[action.uploadedFile].isImporting,
    },
  })),
  on(ImportsActions.importFile, (state, { uploadedFile }) => ({
    ...state,
    [uploadedFile]: { ...state[uploadedFile], isImporting: true },
  })),
  on(ImportsActions.importFileSuccess, ImportsActions.importFileFailure, (state, action) => ({
    ...state,
    [action.uploadedFile]: {
      ...state[action.uploadedFile],
      isImporting: false,
      recentlyImported: action.type === ImportsActions.importFileSuccess.type,
      panelExpanded: action.type === ImportsActions.importFileFailure.type,
    },
  })),
  on(ImportsActions.setRecentlyImported, (state, { uploadedFile, recentlyImported }) => ({
    ...state,
    [uploadedFile]: { ...state[uploadedFile], recentlyImported },
  })),
  on(ImportsActions.setPanelExpanded, (state, { uploadedFile, panelExpanded }) => ({
    ...state,
    [uploadedFile]: { ...state[uploadedFile], panelExpanded },
  }))
);

export function reducer(state: ImportsState | undefined, action: Action) {
  return importsReducer(state, action);
}
