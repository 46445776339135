import { User } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

export const authenticateUser = createAction('[Auth/User] Authenticate User', props<{ samlResponse: string }>());

export const authenticateUserSuccess = createAction('[Auth/User] Authenticate User Success');

export const authenticateUserFailure = createAction('[Auth/User] Authenticate User Failure');

export const authenticateRedirect = createAction('[Auth/User] Authenticate Redirect Success');

export const loginUser = createAction('[Auth/User] Login User');

export const loginUserSuccess = createAction('[Auth/User] Login User Success', props<{ currentUser: User }>());

export const logoutUser = createAction('[Auth/User] Logout User');

export const logoutUserSuccess = createAction('[Auth/User] Logout User Success');

export const forbidden = createAction('[Auth/User] Access Forbidden');

export const notAuthorized = createAction(
  '[Auth/User] Access Not Authorized',
  props<{ titleTranslationKey?: string }>()
);
