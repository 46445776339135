import moment from 'moment';

import { Allocation, BacktestParameters, Position, Project } from '../models';
import {
  BackendAllocation,
  BackendBacktestParameters,
  BackendPosition,
  BackendProject,
} from '../models/backend-http-request';
import { formatRequestDate } from '../tools/date.tools';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class BackendResponseAdapter {
  static projectResponseToProject(backendProject: BackendProject): Project {
    const {
      id,
      userId,
      name,
      currency,
      creditRate,
      financialProfile,
      hedging: hedge,
      lastModified,
      isArchived,
    } = backendProject;

    return {
      id,
      userId,
      name,
      currency,
      creditRate,
      financialProfile,
      hedge,
      lastModified,
      missingAssets: false,
      missingFinancialProfile: false,
      isArchived,
      allocations: backendProject.allocationModels.map((allocation, index) =>
        this.allocationResponseToAllocation(allocation, index)
      ),
      backtestParameters: backendProject.backtestParameters
        ? this.backtestResponseToBacktest(backendProject.backtestParameters, backendProject.allocationModels.length)
        : undefined,
    };
  }

  private static allocationResponseToAllocation(allocation: BackendAllocation, allocationId: number): Allocation {
    return {
      id: allocationId,
      name: allocation.name,
      positions: allocation.positions.map(position => this.positionResponseToPosition(position)),
    };
  }

  private static backtestResponseToBacktest(
    backtestParameters: BackendBacktestParameters,
    benchmarkId: number
  ): BacktestParameters {
    return {
      startDate: formatRequestDate(moment(backtestParameters.startDate)),
      endDate: formatRequestDate(moment(backtestParameters.endDate)),
      benchmark: {
        id: benchmarkId,
        name: 'benchmark',
        positions: [{ instrumentId: parseInt(backtestParameters.benchmark, 10), positionValue: 100 }],
      },
    };
  }

  private static positionResponseToPosition(position: BackendPosition): Position {
    return {
      instrumentId: parseInt(position.assetClass, 10),
      positionValue: position.weight,
    };
  }
}
