<button
  *ngIf="urlPath === '/analysis' && (exportAnalysisRunning$ | async) === false"
  mat-icon-button
  class="export-button"
  [matMenuTriggerFor]="exportMenu"
>
  <mat-icon> get_app </mat-icon>
</button>

<mat-menu #exportMenu="matMenu" class="export-menu">
  <button mat-menu-item disableRipple="true" class="export-menu-button" (click)="exportAnalysisResults()">
    <img alt="pptx-icon" class="export-icon" ngSrc="/assets/images/powerpoint-icon.png" height="24" width="24" />
  </button>
  <button mat-menu-item disableRipple="true" class="export-menu-button" (click)="exportCsv()">
    <img alt="xlsx-icon" class="export-icon" ngSrc="/assets/images/excel-icon.png" height="24" width="24" />
  </button>
</mat-menu>
