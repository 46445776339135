<svg
  class="svg-icon"
  [class.icon-active]="linkActive === true"
  viewBox="0 0 22 22"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Icons" stroke="none" stroke-width="1" [class.icon-active]="linkActive === true" fill-rule="evenodd">
    <g id="Simulation">
      <path
        d="M22,22 L18,22 L18,9 C18,8.448 18.448,8 19,8 L21,8 C21.552,8 22,8.448 22,9 L22,22 Z"
        id="Shape"
        fill-rule="nonzero"
      ></path>
      <path
        d="M16,22 L12,22 L12,13 C12,12.448 12.448,12 13,12 L15,12 C15.552,12 16,12.448 16,13 L16,22 Z"
        id="Shape"
        fill-rule="nonzero"
      ></path>
      <path
        d="M10,22 L6,22 L6,11 C6,10.448 6.448,10 7,10 L9,10 C9.552,10 10,10.448 10,11 L10,22 Z"
        id="Shape"
        fill-rule="nonzero"
      ></path>
      <path
        d="M4,22 L0,22 L0,15 C0,14.448 0.448,14 1,14 L3,14 C3.552,14 4,14.448 4,15 L4,22 Z"
        id="Shape"
        fill-rule="nonzero"
      ></path>
      <circle id="Oval" fill-rule="nonzero" cx="20" cy="2" r="2"></circle>
      <circle id="Oval" fill-rule="nonzero" cx="14" cy="7" r="2"></circle>
      <circle id="Oval" fill-rule="nonzero" cx="8" cy="4" r="2"></circle>
      <circle id="Oval" fill-rule="nonzero" cx="2" cy="8" r="2"></circle>
      <polyline
        class="stroke-fill"
        id="Shape"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        points="2 8 8 4 14 7 20 2"
      ></polyline>
    </g>
  </g>
</svg>
