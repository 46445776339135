import { ChangeDetectionStrategy, Component, Input, isDevMode, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '@app/core/services/sidenav.service';
import * as fromRoot from '@app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'am-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  @ViewChild('sidenavRef')
  set sidenav(sidenav: MatSidenav) {
    if (sidenav) {
      this._sidenav = sidenav;
      this._sidenavService.setSidenav(this.sidenav);
    }
  }

  get sidenav(): MatSidenav {
    return this._sidenav;
  }

  @Input() urlPath: string;

  exportAnalysisRunning$: Observable<boolean>;

  isScreenWide$: Observable<boolean>;

  isDevMode = isDevMode();

  private _sidenav: MatSidenav;

  constructor(
    private readonly _store: Store<fromRoot.State>,
    private readonly _sidenavService: SidenavService
  ) {}

  ngOnInit(): void {
    this.isScreenWide$ = this._store.pipe(select(fromRoot.getIsScreenWide));
    this.exportAnalysisRunning$ = this._store.pipe(select(fromRoot.getExportAnalysisRunning));
  }

  openSideNav(): void {
    this._sidenavService.open();
  }

  closeSidenav(): void {
    this._sidenavService.close();
  }
}
