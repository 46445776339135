import { CommonModule, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import {
  ExportMenuComponent,
  FooterComponent,
  LangMenuComponent,
  SidebarComponent,
  SimulationIconComponent,
  ThemeMenuComponent,
  UserMenuComponent,
} from './components';
import { LayoutComponent } from './containers/layout/layout.component';
import { HttpErrorInterceptor, NoCacheInterceptor } from './interceptors';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SidenavService } from './services/sidenav.service';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    SimulationIconComponent,
    FooterComponent,
    LangMenuComponent,
    UserMenuComponent,
    ExportMenuComponent,
    ThemeMenuComponent,
  ],
  exports: [LayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    TranslocoModule,
    RouterModule,
    MatIcon,
    NgOptimizedImage,
  ],
  providers: [
    SidenavService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
