import { AvailableImport } from '@app/core/const/importer';
import { LatestImportDates } from '@app/core/models';
import { createAction, props } from '@ngrx/store';

export const getAllImportsDates = createAction('[Imports/All] Get All Imports Date');
export const getAllImportsDatesSuccess = createAction(
  '[Imports/All] Get All Imports Date Success',
  props<{ allImportsDates: LatestImportDates }>()
);
export const getAllImportsDatesFailure = createAction(
  '[Imports/All] Get All Imports Date Failure',
  props<{ errorLabel: string }>()
);

export const getLatestImportDate = createAction('[Imports/Latest] Get Latest Import Date');

export const getLatestImportDateFailure = createAction('[Imports/Latest] Get Latest Import Date Failure');

export const getOneImportDate = createAction(
  '[Imports/OneDate] Get One Import Date',
  props<{ uploadedFile: AvailableImport }>()
);
export const getOneImportDateSuccess = createAction(
  '[Imports/OneDate] Get One Import Date Success',
  props<{ uploadedFile: AvailableImport; date: string }>()
);
export const getOneImportDateFailure = createAction(
  '[Imports/OneDate] Get One Import Date Failure',
  props<{ uploadedFile: AvailableImport; errorLabel: string }>()
);

export const importFile = createAction(
  '[Imports/File] Import New File',
  props<{ uploadedFile: AvailableImport; file: File }>()
);
export const importFileSuccess = createAction(
  '[Imports/File] Import New File Success',
  props<{ uploadedFile: AvailableImport }>()
);
export const importFileFailure = createAction(
  '[Imports/File] Import New File Failure',
  props<{ uploadedFile: AvailableImport }>()
);

export const setRecentlyImported = createAction(
  '[Imports/RecentlyImported ] Set Recently Imported',
  props<{ uploadedFile: AvailableImport; recentlyImported: boolean }>()
);

export const setPanelExpanded = createAction(
  '[Imports/PanelExpanded] Set Panel Expanded',
  props<{ uploadedFile: AvailableImport; panelExpanded: boolean }>()
);
