import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BacktestService } from '@app/analysis-results/services/backtest.service';
import { ExanteAnalysisChartsService } from '@app/analysis-results/services/exante-analysis-charts.service';
import { AvailableCountry } from '@app/core/const/country';
import { PPT_EXPORT_TAGS } from '@app/core/const/export';
import { AvailableLang } from '@app/core/const/i18n';
import { Allocation, Asset, AvailableLiquidity, Project, TemplateFileInfos, TranslationObject } from '@app/core/models';
import { Tag } from '@app/core/models/tag';
import { LocalizedDatePipe } from '@app/core/pipes';
import { downloadFile } from '@app/core/tools/file-saver';
import { isValidJSONString } from '@app/core/tools/json.tools';
import { getAnalyticsData } from '@app/core/tools/smart-risk.tools';
import { ForecastChartsService } from '@app/forecast/services/forecast-charts.service';
import { TranslocoService } from '@ngneat/transloco';
import * as echarts from 'echarts';
import { EChartsOption, SeriesOption } from 'echarts';
import { OptionDataItemObject, OptionDataValue } from 'echarts/types/src/util/types';
import moment from 'moment';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const EXPORT_ENDPOINT = `${environment.base_sr_tools_url}export-templates`;
const GET_TEMPLATE_ENDPOINT = `${environment.base_sr_tools_url}administration/pptx-templates`;

@Injectable({
  providedIn: 'root',
})
export class PptxExportService {
  constructor(
    private readonly http: HttpClient,
    private readonly exanteAnalysisChartsService: ExanteAnalysisChartsService,
    private readonly forecastChartsService: ForecastChartsService,
    private readonly backtestService: BacktestService,
    private readonly translocoService: TranslocoService,
    private readonly localizeDate: LocalizedDatePipe
  ) {}

  getAnalysisResultsExport(
    project: Project,
    assetsList: Asset[],
    availableLiquidities: AvailableLiquidity[],
    country: AvailableCountry,
    currentLang: AvailableLang
  ): Observable<ArrayBuffer> {
    return combineLatest([
      this.http.get<TemplateFileInfos[]>(`${GET_TEMPLATE_ENDPOINT}`),
      this.getTags(project, assetsList, availableLiquidities, currentLang, country),
    ]).pipe(
      switchMap(([templatesFileInfos, tags]) => {
        const storagePptxFileId = this.getMostRecentTemplateId(templatesFileInfos, country, currentLang);
        return this.http
          .post(
            `${EXPORT_ENDPOINT}/${storagePptxFileId}`,
            { tags },
            {
              headers: {
                Accept: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              },
              responseType: 'arraybuffer',
            }
          )
          .pipe(
            tap(file => {
              const fileName = `${project.name}-${country}-${currentLang}.pptx`;
              downloadFile(
                file,
                'application / vnd.openxmlformats - officedocument.presentationml.presentation',
                fileName
              );
            })
          );
      }),
      catchError(error => {
        throw new Error(this.handleExportError(error));
      })
    );
  }

  private getMostRecentTemplateId(
    templatesFileInfos: TemplateFileInfos[],
    country: AvailableCountry,
    currentLang: AvailableLang
  ): number {
    const countryAndLanguageFiles = templatesFileInfos.filter(x => x.country === country && x.language === currentLang);

    if (countryAndLanguageFiles.length === 0) {
      const messageKey = {
        key: 'errors.export.missing-template',
        params: { country, currentLang: currentLang.toUpperCase() },
      };
      throw new Error(JSON.stringify(messageKey));
    }

    const mostRecentTemplate = countryAndLanguageFiles
      .slice(1, countryAndLanguageFiles.length)
      .reduce((currentTemplateFileInfos, templateFileInfos) => {
        if (moment(templateFileInfos.lastModified).isAfter(moment(currentTemplateFileInfos.lastModified))) {
          currentTemplateFileInfos = templateFileInfos;
        }
        return currentTemplateFileInfos;
      }, countryAndLanguageFiles[0]);

    return mostRecentTemplate.id;
  }

  private getTags(
    project: Project,
    assetsList: Asset[],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang,
    country: AvailableCountry
  ): Observable<Record<string, Tag>> {
    const format = currentLang === 'fr' || currentLang === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    const financialProfiles = assetsList[0].profiles.filter(profile => !profile.value.includes('-HEDGE'));
    const financialProfile = financialProfiles.find(
      financialProfileItem => financialProfileItem.value === project.financialProfile
    );
    const allocationsContributionOptions = project.allocations.map(allocation =>
      this.exanteAnalysisChartsService.getContributionOptions(allocation, assetsList, currentLang)
    );

    return combineLatest([
      this.translocoService.selectTranslation(`simulation/${currentLang}`),
      this.getForecastsChart(project, assetsList, currentLang),
      this.getAllocationsTags(
        project.allocations,
        allocationsContributionOptions,
        assetsList,
        availableLiquidities,
        currentLang
      ),
      this.getAllocationsComparisonCharts(
        project.allocations,
        assetsList,
        allocationsContributionOptions,
        availableLiquidities,
        currentLang
      ),
      this.getBacktestTags(project, assetsList, currentLang, country),
    ]).pipe(
      map(([_, forecastsChart, allocationsTags, allocationsComparisonCharts, backtestCharts]) => {
        return {
          [PPT_EXPORT_TAGS.date]: {
            type: 'String',
            value: moment().format(format),
          },
          [PPT_EXPORT_TAGS.financialProfile]: {
            type: 'String',
            value: financialProfile
              ? financialProfile.label[currentLang]
              : this.translocoService.translate('simulation.financial-profiles.no-profile-found'),
          },
          [PPT_EXPORT_TAGS.currency]: {
            type: 'String',
            value: project.currency,
          },
          [PPT_EXPORT_TAGS.hedging]: {
            type: 'String',
            value: project.hedge
              ? this.translocoService.translate('simulation.project-details.hedged')
              : this.translocoService.translate('simulation.project-details.unhedged'),
          },
          [PPT_EXPORT_TAGS.forecasts]: {
            type: 'ImageBase64',
            value: forecastsChart,
          },
          ...allocationsTags,
          ...allocationsComparisonCharts,
          ...backtestCharts,
        };
      })
    );
  }

  private getForecastsChart(project: Project, assetsList: Asset[], currentLang: AvailableLang): Observable<string> {
    return this.translocoService.selectTranslation(`forecast/${currentLang}`).pipe(
      switchMap(() => {
        const forecastOptions = this.forecastChartsService.getForecastOptions(
          project.currency,
          project.hedge,
          assetsList,
          currentLang
        );
        return this.getBase64(forecastOptions, 1066, 423);
      })
    );
  }

  private getAllocationsTags(
    projectAllocations: Allocation[],
    allocationsContributionOptions: EChartsOption[],
    assetsList: Asset[],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): Observable<Record<string, Tag>> {
    const contributionChartsObs = allocationsContributionOptions.map(allocationContributionOption =>
      this.getBase64(allocationContributionOption, 480, 480).pipe(map(value => ({ type: 'ImageBase64', value })))
    );

    const contributionZoomChartsObs = this.exanteAnalysisChartsService
      .getContributionZoomOptions(projectAllocations, assetsList, currentLang)
      .map(allocContribOption => {
        if ((allocContribOption.baseOption.series as SeriesOption[]).length > 0) {
          return this.getBase64(allocContribOption, 350, 465).pipe(map(value => ({ type: 'ImageBase64', value })));
        }
        return of({ type: 'String', value: '' });
      });
    const liquidityChartsObs = projectAllocations.map(allocation => {
      return this.getBase64(
        this.exanteAnalysisChartsService.getExportLiquidityOptions(
          allocation.exAnteAnalysis.liquidity,
          availableLiquidities,
          currentLang
        ),
        320,
        149
      ).pipe(map(value => ({ type: 'ImageBase64', value })));
    });

    return combineLatest([...contributionChartsObs, ...contributionZoomChartsObs, ...liquidityChartsObs]).pipe(
      map(charts => {
        const contributionCharts = charts.slice(0, projectAllocations.length);
        const contributionZoomCharts = charts.slice(projectAllocations.length, projectAllocations.length * 2);
        const liquidityCharts = charts.slice(projectAllocations.length * 2, charts.length);
        return projectAllocations.reduce((allocationsTags, allocation, index) => {
          const allocationTags = PPT_EXPORT_TAGS.allocations[index];
          const allocContribZoom =
            contributionZoomCharts[index].value !== ''
              ? { [PPT_EXPORT_TAGS.allocations[index].allocContribZoom]: contributionZoomCharts[index] }
              : undefined;

          return {
            ...allocationsTags,
            ...allocContribZoom,
            [allocationTags.allocNum]: { type: 'String', value: `${index + 1}` },
            [allocationTags.allocContrib]: contributionCharts[index],
            [allocationTags.allocPerformance]: {
              type: 'String',
              value: `${allocation.exAnteAnalysis.allocationResult.return}%`,
            },
            [allocationTags.allocCurrentYield]: {
              type: 'String',
              value: `${allocation.exAnteAnalysis.allocationResult.yield}%`,
            },
            [allocationTags.allocRisk]: {
              type: 'String',
              value: `${allocation.exAnteAnalysis.allocationResult.risk}%`,
            },
            [allocationTags.allocVar]: {
              type: 'String',
              value: `-${allocation.exAnteAnalysis.allocationResult.normalVar}%`,
            },
            [allocationTags.sharpeRatio]: {
              type: 'String',
              value: `${allocation.exAnteAnalysis.allocationResult.sharpeRatio}`,
            },
            [allocationTags.allocLiquidity]: liquidityCharts[index],
          };
        }, {});
      })
    );
  }

  private getAllocationsComparisonCharts(
    projectAllocations: Allocation[],
    assetsList: Asset[],
    allocationsContributionOptions: EChartsOption[],
    availableLiquidities: AvailableLiquidity[],
    currentLang: AvailableLang
  ): Observable<Record<string, Tag>> {
    const comparisonOptions = this.exanteAnalysisChartsService.getComparisonContributionOptions(
      allocationsContributionOptions,
      assetsList,
      currentLang
    );
    const yieldValues = projectAllocations.map(allocation => allocation.exAnteAnalysis.allocationResult.yield);
    const analyticsData = getAnalyticsData(projectAllocations);
    const comparisonCharts = comparisonOptions.map(comparisonOption => this.getBase64(comparisonOption, 480, 453));

    const analyticsCharts = Object.values(analyticsData)
      .filter((_, index) => index !== 3)
      .map((analyticsValue: number[], index) =>
        this.getBase64(
          this.exanteAnalysisChartsService.getAnalyticsOptions({
            displayedAsNegative: index === 2,
            values: analyticsValue,
          }),
          217,
          105
        )
      );
    analyticsCharts.push(
      this.getBase64(
        this.exanteAnalysisChartsService.getExportLiquiditiesOptions(
          analyticsData.liquidities,
          availableLiquidities,
          currentLang
        ),
        392,
        138
      )
    );
    analyticsCharts.push(
      this.getBase64(
        this.exanteAnalysisChartsService.getAnalyticsOptions({
          displayedAsNegative: false,
          values: yieldValues,
        }),
        217,
        105
      )
    );

    return combineLatest([...comparisonCharts, ...analyticsCharts]).pipe(
      map(([compW, compP, compR, returns, risks, normalVars, liquidities, yields]) => {
        const comparisonTags = PPT_EXPORT_TAGS.comparison;

        return {
          [comparisonTags.compareWeights]: { type: 'ImageBase64', value: compW },
          [comparisonTags.comparePerformance]: { type: 'ImageBase64', value: compP },
          [comparisonTags.compareRisk]: { type: 'ImageBase64', value: compR },
          [comparisonTags.allocsPerformance]: { type: 'ImageBase64', value: returns },
          [comparisonTags.allocsCurrentYield]: { type: 'ImageBase64', value: yields },
          [comparisonTags.allocsRisk]: { type: 'ImageBase64', value: risks },
          [comparisonTags.allocsVar]: { type: 'ImageBase64', value: normalVars },
          [comparisonTags.liquidity]: { type: 'ImageBase64', value: liquidities },
        };
      })
    );
  }

  private getBacktestTags(
    project: Project,
    assetsList: Asset[],
    currentLang: AvailableLang,
    country: AvailableCountry
  ): Observable<Record<string, Tag>> {
    const benchmarkAssetId = project.backtestParameters
      ? project.backtestParameters.benchmark.positions[0].instrumentId
      : BacktestService.defaultAssetId;
    const assetReference = assetsList.find(asset => asset.id === benchmarkAssetId);

    return this.getBacktestCharts(project, currentLang, country).pipe(
      map(backtestCharts => {
        const backtestPeriod = moment(project.backtestParameters.endDate).diff(
          moment(project.backtestParameters.startDate),
          'years',
          true
        );
        const backtestPptTags = PPT_EXPORT_TAGS.backtest;

        return {
          [backtestPptTags.backtestPeriod]: {
            type: 'String',
            value: `${Math.round(backtestPeriod)} ${this.translocoService.translate(
              'analysisResults.backtest.period-year'
            )}`,
          },
          [backtestPptTags.startDate]: {
            type: 'String',
            value: this.localizeDate.transform(project.backtestParameters.startDate, 'MMM yyyy'),
          },
          [backtestPptTags.endDate]: {
            type: 'String',
            value: this.localizeDate.transform(project.backtestParameters.endDate, 'MMM yyyy'),
          },
          [backtestPptTags.assetReference]: { type: 'String', value: assetReference.name[currentLang] },
          [backtestPptTags.allocsMaxDrawdown]: { type: 'ImageBase64', value: backtestCharts.maxDrawDown },
          [backtestPptTags.allocsBacktest]: { type: 'ImageBase64', value: backtestCharts.backtest },
        };
      })
    );
  }

  private getBacktestCharts(
    project: Project,
    currentLang: AvailableLang,
    country: AvailableCountry
  ): Observable<{ maxDrawDown: string; backtest: string }> {
    const allBacktestOptions = this.backtestService.getAllBacktestOptions(
      project.backtestResults,
      currentLang,
      country === AvailableCountry.fra
    );

    return combineLatest([
      this.getBase64(allBacktestOptions.mawDrawDownOptions, 424, 127),
      this.getBase64(allBacktestOptions.backtestOptions, 990, 332),
    ]).pipe(map(([maxDrawDown, backtest]) => ({ maxDrawDown, backtest })));
  }

  private getBase64(option: EChartsOption, width: number, height: number): Observable<string> {
    const divChart: HTMLDivElement = document.createElement('div');
    const series: SeriesOption[] = this.isResponsiveOption(option)
      ? (option.baseOption.series as SeriesOption[])
      : (
          option as {
            series: SeriesOption[];
          }
        ).series;
    const chartSeriesEmpty = series.every(
      seriesItem =>
        !seriesItem.data ||
        (seriesItem.data as []).every(
          dataItem => !dataItem || !(dataItem as OptionDataItemObject<OptionDataValue>).value
        )
    );

    const chart = echarts.init(divChart, undefined, {
      devicePixelRatio: 1,
      renderer: 'canvas',
      width: width * 1.3,
      height: height * 1.3,
    });
    chart.setOption(option);

    if (chartSeriesEmpty) {
      return of(this.getChartDataURL(chart));
    }

    const finishedSub = new Subject<string>();
    chart.on('finished', () => {
      finishedSub.next(this.getChartDataURL(chart));
    });

    return from(finishedSub);
  }

  private isResponsiveOption(option: EChartsOption): option is EChartsOption {
    return 'baseOption' in option;
  }

  private getChartDataURL(chart: echarts.ECharts): string {
    return chart
      .getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff',
      })
      .replace('data:image/png;base64,', '');
  }

  private handleExportError(error: Error | HttpErrorResponse): string {
    let messageKey: TranslationObject = { key: 'errors.export.default-export' };
    if (error instanceof HttpErrorResponse && error.url === GET_TEMPLATE_ENDPOINT) {
      messageKey = { key: 'errors.export.default-template' };
    }
    if (error.message && isValidJSONString(error.message)) {
      const jsonError = JSON.parse(error.message);
      if (jsonError.key && jsonError.params) {
        messageKey = {
          key: jsonError.key,
          params: { country: jsonError.params.country, currentLang: jsonError.params.currentLang },
        };
      }
    }
    return JSON.stringify(messageKey);
  }
}
