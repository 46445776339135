import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel } from '@angular/material/snack-bar';
import { LocalizedDatePipe } from '@app/core/pipes';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

import { ConfirmationModalComponent, SnackBarComponent, TooltipModalComponent } from './components';

@NgModule({
  declarations: [ConfirmationModalComponent, TooltipModalComponent, SnackBarComponent, LocalizedDatePipe],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    TranslocoModule,
    MatIcon,
    MatSnackBarActions,
    MatSnackBarAction,
    MatSnackBarLabel,
  ],
  exports: [ConfirmationModalComponent, TooltipModalComponent, SnackBarComponent, LocalizedDatePipe],
  providers: [LocalizedDatePipe, { provide: TRANSLOCO_SCOPE, useValue: 'shared' }],
})
export class SharedModule {}
