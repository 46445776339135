import { Injectable } from '@angular/core';
import { AvailableLang } from '@app/core/const/i18n';
import { palette } from '@app/core/const/palette';
import * as fromEntities from '@app/core/entities/reducers';
import { Allocation, Asset, BacktestParameters, BacktestResult, Histories } from '@app/core/models';
import { formatRequestDate } from '@app/core/tools/date.tools';
import * as fromRoot from '@app/store/reducers';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { BacktestSetupParams, FirstAndLastDate } from '../models/backtest-types';
import { BacktestChartsService } from './backtest-charts.service';
import { ExanteAnalysisChartsService } from './exante-analysis-charts.service';

@Injectable({
  providedIn: 'root',
})
export class BacktestService {
  static readonly defaultAssetId = 10;

  constructor(
    private readonly _store: Store<fromRoot.State>,
    private readonly _backtestChartsService: BacktestChartsService,
    private readonly _exanteAnalysisChartsService: ExanteAnalysisChartsService,
    private readonly _translocoService: TranslocoService
  ) {}

  getFirstAndLastDates(
    projectCurrency: string,
    projectAllocations: Allocation[],
    benchmarkAssetId: number = BacktestService.defaultAssetId
  ): Observable<FirstAndLastDate> {
    return combineLatest(
      this._store.pipe(
        select(fromEntities.getAllAssets),
        filter(assets => !!assets.find(asset => asset.histories))
      ),
      this._store.pipe(
        select(fromEntities.getFxRatesHistories),
        filter(fxRatesHistories => fxRatesHistories && fxRatesHistories.length > 0)
      )
    ).pipe(
      take(1),
      map(([assets, fxRatesHistories]) => {
        const allocationsAssets: Asset[] = projectAllocations.reduce((retrievedAssets: Asset[], allocation) => {
          const currentAllocationAssets = allocation.positions
            .map(position =>
              assets.find(asset => {
                if (asset.id !== position.instrumentId) {
                  return false;
                }
                return !retrievedAssets.find(allocationsAsset => allocationsAsset.id === asset.id);
              })
            )
            .filter(asset => !!asset);
          return [...retrievedAssets, ...currentAllocationAssets];
        }, []);
        const benchmarkAsset = assets.find(asset => benchmarkAssetId === asset.id);

        return {
          firstDate: this.getDate(projectCurrency, allocationsAssets, benchmarkAsset, fxRatesHistories, 'first'),
          lastDate: this.getDate(projectCurrency, allocationsAssets, benchmarkAsset, fxRatesHistories, 'last'),
        };
      })
    );
  }

  getBacktestSetupParams(
    firstAndLastDate: FirstAndLastDate,
    projectBacktestParameters: BacktestParameters
  ): BacktestSetupParams {
    let backtestPeriods = [15, 10, 5, 3];
    const benchmarkAssetId = projectBacktestParameters
      ? projectBacktestParameters.benchmark.positions[0].instrumentId
      : BacktestService.defaultAssetId;
    const firstDate = moment(firstAndLastDate.firstDate);
    const lastDate = moment(firstAndLastDate.lastDate);
    let startDate: moment.Moment;
    let endDate: moment.Moment;
    if (projectBacktestParameters) {
      endDate = moment(projectBacktestParameters.endDate);
      startDate = moment(projectBacktestParameters.startDate);
      const period = endDate.diff(startDate, 'month');

      if (endDate.isBefore(firstDate)) {
        startDate = firstDate;
        endDate = moment(firstDate).add(period, 'month');
      }
      if (endDate.isAfter(lastDate)) {
        endDate = lastDate;
        startDate = moment(lastDate).subtract(period, 'month');
      }
      if (startDate.isBefore(firstDate)) {
        startDate = firstDate;
      }

      backtestPeriods = backtestPeriods.filter(backtestPeriod => backtestPeriod <= lastDate.diff(firstDate, 'years'));
    } else {
      endDate = lastDate;
      startDate = this.getStartDate(backtestPeriods, endDate, startDate, firstDate);
    }

    return {
      assetId: benchmarkAssetId,
      startDate: formatRequestDate(startDate),
      endDate: formatRequestDate(endDate),
      backtestPeriods,
    };
  }

  getAllBacktestOptions(backtestResults: BacktestResult[], currentLang: AvailableLang, isFraExport = false) {
    const backtestParams = {
      benchmarkFullName: this._translocoService.translate('analysisResults.backtest.ref-asset'),
      benchmarkColor: palette.orange,
    };
    const maxDrawDowns = backtestResults.map(backtest => backtest.maxDrawDown);
    const maxDrawDownsParams = {
      ...backtestParams,
      benchmarkLabel: this._translocoService.translate(
        isFraExport ? 'analysisResults.backtest.export-ref' : 'analysisResults.backtest.ref'
      ),
      allocPrefix: isFraExport ? 'Allocation ' : '',
    };

    const mawDrawDownOptions = this._exanteAnalysisChartsService.getAnalyticsOptions(
      { displayedAsNegative: true, values: maxDrawDowns },
      maxDrawDownsParams
    );
    const backtestOptions = this._backtestChartsService.getBacktestOptions(
      backtestResults,
      backtestParams,
      currentLang
    );
    return { mawDrawDownOptions, backtestOptions };
  }

  private getDate(
    projectCurrency: string,
    allocationsAssets: Asset[],
    benchmarkAsset: Asset,
    fxRatesHistories: Histories[],
    dateToGet: 'first' | 'last'
  ): Date {
    const allocationsDates = allocationsAssets.map(asset => {
      let date = moment(asset.histories[dateToGet].date);
      if (projectCurrency !== asset.currency) {
        const fxRateHistories = this.getFxRateHistories(projectCurrency, asset.currency, fxRatesHistories);
        const fxRateDate = moment(fxRateHistories[dateToGet].date);
        date =
          (dateToGet === 'first' && fxRateDate.isAfter(date)) || (dateToGet === 'last' && fxRateDate.isBefore(date))
            ? fxRateDate
            : date;
      }
      return date;
    });

    let benchmarkDate = moment(benchmarkAsset.histories[dateToGet].date);
    if (projectCurrency !== benchmarkAsset.currency) {
      const fxRateHistories = this.getFxRateHistories(projectCurrency, benchmarkAsset.currency, fxRatesHistories);
      const fxRateDate = moment(fxRateHistories[dateToGet].date);
      benchmarkDate =
        (dateToGet === 'first' && fxRateDate.isAfter(benchmarkDate)) ||
        (dateToGet === 'last' && fxRateDate.isBefore(benchmarkDate))
          ? fxRateDate
          : benchmarkDate;
    }

    return dateToGet === 'first'
      ? moment.max([...allocationsDates, benchmarkDate]).toDate()
      : moment.min([...allocationsDates, benchmarkDate]).toDate();
  }

  private getStartDate(
    backtestPeriods: number[],
    endDate: moment.Moment,
    startDate: moment.Moment,
    firstDate: moment.Moment
  ) {
    let removePeriod = false;
    do {
      if (removePeriod) {
        backtestPeriods.shift();
      }
      const endDateSubPeriod = moment(endDate).subtract(backtestPeriods[0], 'years');
      startDate = firstDate.isAfter(endDateSubPeriod) ? firstDate : endDateSubPeriod;
      if (!removePeriod) {
        removePeriod = true;
      }
    } while (endDate.diff(startDate, 'years') < backtestPeriods[0]);
    return startDate;
  }

  private getFxRateHistories(projectCurrency: string, assetCurrency: string, fxRatesHistories: Histories[]): Histories {
    return fxRatesHistories.find(
      fxRateHistoriesitem => fxRateHistoriesitem.instrumentId === `${assetCurrency}${projectCurrency}`
    );
  }
}
