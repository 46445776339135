import { Component, OnDestroy } from '@angular/core';
import { DISCLAIMERS } from '@app/core/const/disclaimers';
import * as fromRoot from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'am-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnDestroy {
  disclaimer: string[];

  private _destroyed$ = new Subject<void>();

  constructor(private readonly _store: Store<fromRoot.State>) {
    combineLatest([
      this._store.select(fromRoot.getDisclaimerToDisplay),
      this._store.select(fromRoot.getCurrentLang),
      this._store.select(fromRoot.getCountry),
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([disclaimerToDisplay, currentLang, country]) => {
        const matchedDisclaimer = DISCLAIMERS.find(disclaimer => disclaimer.displayOn === disclaimerToDisplay);
        this.disclaimer =
          matchedDisclaimer?.disclaimer[country] != null
            ? matchedDisclaimer.disclaimer[country][currentLang]
            : undefined;
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
