import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';

import { UserRole } from './core/const/user';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    data: {
      role: UserRole.user,
    },
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  {
    path: 'simulation',
    loadChildren: () => import('./simulation/simulation.module').then(m => m.SimulationModule),
    canActivate: [AuthGuard],
    data: {
      role: UserRole.user,
    },
  },
  {
    path: 'analysis',
    loadChildren: () => import('./analysis-results/analysis-results.module').then(m => m.AnalysisResultsModule),
    canActivate: [AuthGuard],
    data: {
      role: UserRole.user,
    },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      role: UserRole.admin,
    },
  },
  {
    path: 'forecast',
    loadChildren: () => import('./forecast/forecast.module').then(m => m.ForecastModule),
    canActivate: [AuthGuard],
    data: {
      role: UserRole.user,
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
