import { createAction, props } from '@ngrx/store';

import { Category } from '../../models';

export const loadCategories = createAction('[Entities/Categories] Load Categories');

export const loadCategoriesSuccess = createAction(
  '[Entities/Categories] Load Categories Success',
  props<{ categories: Category[] }>()
);
