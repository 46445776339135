import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AVAILABLE_IMPORTS, AVAILABLE_IMPORTS_URLS, AvailableImport } from '../const/importer';
import { LatestImportDates, SrapiHttpResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ImporterService {
  private readonly importerUrl: string;

  constructor(private readonly http: HttpClient) {
    this.importerUrl = environment.base_importer_url;
  }

  getAllLatestImports(): Observable<LatestImportDates> {
    const requests = AVAILABLE_IMPORTS.map(value => {
      return this.http.get<string>(`${this.importerUrl}srds/${AVAILABLE_IMPORTS_URLS[value]}/last-import`);
    });
    return forkJoin(requests).pipe(
      map(latestImportsDate => {
        return latestImportsDate.reduce(
          (latestImports, lastImportDate, index) => {
            latestImports[AVAILABLE_IMPORTS[index]] = lastImportDate ? lastImportDate : 'admin.data-importer.no-file';
            return latestImports;
          },
          { covariancesViews: '', namesClassifications: '', forexHistories: '' }
        );
      })
    );
  }

  getLatestImport(uploadedFile: AvailableImport): Observable<string> {
    return this.http.get<string>(`${this.importerUrl}srds/${AVAILABLE_IMPORTS_URLS[uploadedFile]}/last-import`);
  }

  importFile(file: File, uploadedFile: AvailableImport): Observable<SrapiHttpResponse> {
    const formData = new FormData();
    formData.append(uploadedFile, file);
    const mandatoryColumns = AvailableImport.namesClassifications
      ? { NamesImporterJob: ['LIQUIDITY', 'SHORT-NAME'] }
      : undefined;
    if (mandatoryColumns) {
      const stringifiedMandatoryColumns = JSON.stringify({
        MandatoryFieldsPerJob: mandatoryColumns,
      });
      formData.append('MandatoryFields', stringifiedMandatoryColumns);
    }
    return this.http.post<SrapiHttpResponse>(
      `${this.importerUrl}srds/${AVAILABLE_IMPORTS_URLS[uploadedFile]}`,
      formData
    );
  }
}
