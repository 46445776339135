<button mat-icon-button disableRipple="true" color="primary" [matMenuTriggerFor]="userMenu">
  <span class="menu">
    {{ username$ | async }}
    <mat-icon> person </mat-icon>
  </span>
</button>
<mat-menu #userMenu="matMenu">
  <button *ngIf="userIsAdmin$ | async" [routerLink]="['/admin']" mat-menu-item>
    {{ 'layout.administration' | transloco }}
  </button>
  <button mat-menu-item (click)="logout()">
    {{ 'layout.logout' | transloco }}
  </button>
</mat-menu>
