import { AvailableCountry } from '../const/country';

export enum TelemetryEventTypes {
  projectCreation = 'Project Creation',
  userLogin = 'User Login',
}

export type TelemetryDataAction = {
  eventType: TelemetryEventTypes;
  tags?: { financialProfile: string };
};
export type TelemetryEventData = {
  eventType: TelemetryEventTypes;
  userId: string;
  tags: { country: AvailableCountry; financialProfile?: string };
};

export type TelemetryEvent = TelemetryEventData & {
  id: number;
  eventTime: string;
};
