<ng-container *transloco="let t">
  <div mat-dialog-title>
    {{ t(data.titleKey) }}
  </div>
  <mat-dialog-content>
    {{ t(data.content.key, data.content.params) }}
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button *ngIf="data.cancelLabelKey" mat-flat-button color="basic" (click)="onClick(false)">
      {{ t(data.cancelLabelKey) }}
    </button>
    <button mat-flat-button color="primary" (click)="onClick(true)">
      {{ t(data.confirmLabelKey) }}
    </button>
  </mat-dialog-actions>
</ng-container>
