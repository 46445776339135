import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslationObject } from '@app/core/models';

@Component({
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  @HostBinding('class') elementClass = 'mat-simple-snackbar';

  constructor(
    private readonly _snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      title?: string;
      messages: TranslationObject[];
      actionLabel: string;
    }
  ) {}

  onAction(): void {
    this._snackBarRef.dismiss();
  }
}
