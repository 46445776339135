import { SMART_RISK_CONFIG } from '../const/smart-risk-config';
import { Allocation, Asset, InstrumentAnalysisResult, Liquidity, Project } from '../models';

export const parseStringifyNumber = (value: string) => parseFloat(value.replace(',', '.'));

export const floatify = (value: number): number => {
  return parseFloat(value.toFixed(10));
};

export const formatPercentAnalytic = (analytics: number, precisionMultiplier: number): number => {
  return floatify(Math.round(analytics * 100 * precisionMultiplier) / precisionMultiplier);
};

/**
 * Get instrument view value of an asset
 * @param asset asset
 * @param currency currency
 * @param hedge hedge or not
 */
export const getInstrumentExpectedReturn = (asset: Asset, currency: string, hedge: boolean) => {
  const expectedReturn = asset.analyticsData.returns.find(
    returnItem => returnItem.currency === currency && returnItem.hedge === hedge
  );

  return expectedReturn ? expectedReturn.value : null;
};

/**
 * Check if asset is credit
 * Here we check by id
 * If in the future, we need to check asset by name, we just need to modify this function
 * @param asset asset to be checked
 */
export const isAssetCredit = (asset: Asset) => SMART_RISK_CONFIG.asset.creditIds.includes(asset.id);
export const isAssetCash = (asset: Asset) => SMART_RISK_CONFIG.asset.cashIds.includes(asset.id);

export function getAnalyticsData(allocations: Allocation[]): {
  returns: number[];
  risks: number[];
  normalVars: number[];
  liquidities: Liquidity[][];
} {
  return allocations.reduce(
    (analytics, allocation) => {
      if (allocation.exAnteAnalysis) {
        analytics.returns.push(allocation.exAnteAnalysis.allocationResult.return);
        analytics.risks.push(allocation.exAnteAnalysis.allocationResult.risk);
        analytics.normalVars.push(allocation.exAnteAnalysis.allocationResult.normalVar);
        analytics.liquidities.push(allocation.exAnteAnalysis.liquidity);
      }

      return analytics;
    },
    { returns: [], risks: [], normalVars: [], liquidities: [] }
  );
}

export function getYield(project: Project, assetsList: Asset[], instrumentResults: InstrumentAnalysisResult[]): number {
  let yieldValue = 0;
  yieldValue = instrumentResults.reduce((allocYield, instrument) => {
    const asset = assetsList.find(assetItem => assetItem.id === instrument.instrumentId);
    if (asset) {
      const assetYield = asset.analyticsData.yields.find(
        ({ currency, hedge }) => currency === project.currency && hedge === project.hedge
      );
      allocYield += instrument.weight * assetYield.value;
    }

    return allocYield;
  }, yieldValue);
  return Math.round(yieldValue * 10) / 10;
}
