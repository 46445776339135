export const SMART_RISK_CONFIG = {
  asset: {
    cashIds: [1, 23, 24, 25],
    creditIds: [22, 26, 27, 28],
  },
};

export const SORTED_LIQUIDITIES = [
  {
    name: {
      fr: 'Très Faible',
      en: 'Very Low',
      nl: 'Zeer Laag',
      es: 'Muy baja',
    },
    index: 0,
  },
  {
    name: {
      fr: 'Faible',
      en: 'Low',
      nl: 'Laag',
      es: 'Baja',
    },
    index: 1,
  },
  {
    name: {
      fr: 'Forte',
      en: 'High',
      nl: 'Hoog',
      es: 'Alta',
    },
    index: 2,
  },
  {
    name: {
      fr: 'Très Forte',
      en: 'Very High',
      nl: 'Zeer Hoog',
      es: 'Muy alta',
    },
    index: 3,
  },
];
