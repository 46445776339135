import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'am-simulation-icon',
  templateUrl: './simulation-icon.component.html',
  styleUrls: ['./simulation-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationIconComponent {
  @Input() linkActive: boolean;
}
